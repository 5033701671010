import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import { get } from "lodash";
import { getVideo, uploadVideo, getVideoUrl, getVideoDetails, submitVideoUrls, uploadVideoTos3, addVideo, retryVideoAnalysis, getTranscriptionTex, deleteVideo, getCustomObjectsPercent, getDealerVideoRatingPercent, getBrandVideoRatingPercent, getDealerVideosStatusPercent, getBrandTotalVideoCount, getBrandTotalVideoAvgLength, getBrandTotalVideoNoisyRate, getBrandTotalVideoSuccessRate, getBrandTotalVideoFailureRate, getBrandTotalVideoScoreByBrand, getBrandTotalVideoScoreByBrandDealer, getDashboardData, uploadVideoTos3ForScratchDetect, addScratchVideo, getAllScratcheddetectVideo, submitScratchDetectVideoUrls, deleteScratchDetectVideo, getScratchVideoDetails, retryScratchVideoAnalysis } from "../../../services/video.service";

export interface DashboardState {
    videoData: any[];
    paginationData:any;
}

const initialState: DashboardState = {
    videoData: [],
    paginationData:{}
};

export interface IUploadVideo {
    video: string,
    userId: string,
}

export const videoHandler: any = createAsyncThunk(actions.VIDEODATA,
    (data, { rejectWithValue }) =>
        getVideo(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const getScratchDetectVideoHandler: any = createAsyncThunk(actions.SCRATCH_DETECT_VIDEO,
    (data, { rejectWithValue }) =>
        getAllScratcheddetectVideo(data).catch(
            (error) => error && rejectWithValue(error)
        )
);

export const videoUploadHandler: any = createAsyncThunk(actions.UPLOAD_VIDEO, (data: IUploadVideo, { rejectWithValue }) =>
    uploadVideo(data?.video, data?.userId).catch((error) => error && rejectWithValue(error))
);

export const getVideoUrlHandler: any = createAsyncThunk(actions.GET_VIDEO_URL, (data: string, { rejectWithValue }) =>
    getVideoUrl(data).catch((error) => error && rejectWithValue(error))
);

export const getVideoDetailsHandler: any = createAsyncThunk(actions.GET_VIDEO_DETAILS, (data: string, { rejectWithValue }) =>
    getVideoDetails(data).catch((error) => error && rejectWithValue(error)))

export const getScratchVideoDetailsHandler: any = createAsyncThunk(actions.GET_SCRATCH_VIDEO_DETAILS, (data: string, { rejectWithValue }) =>
    getScratchVideoDetails(data).catch((error) => error && rejectWithValue(error)))

export const submitVideoUrlsHandler: any = createAsyncThunk(actions.SUBMIT_VIDEO_URLS, (data: any, { rejectWithValue }) =>
    submitVideoUrls(data).catch((error) => error && rejectWithValue(error)))


export const submitScratchDetectVideoUrlsHandler: any = createAsyncThunk(actions.SUBMIT_SCRATCH_VIDEO_URLS, (data: any, { rejectWithValue }) =>
    submitScratchDetectVideoUrls(data).catch((error) => error && rejectWithValue(error)))

export const videoUploadToS3Handler: any = createAsyncThunk(actions.VIDEO_UPLOAD_S3, (data: any, { rejectWithValue }) =>
    uploadVideoTos3(data).catch((error) => error && rejectWithValue(error))
);
export const videoUploadToS3ForScratchHandler: any = createAsyncThunk(actions.SCRATCH_VIDEO_UPLOAD_S3, (data: any, { rejectWithValue }) =>
    uploadVideoTos3ForScratchDetect(data).catch((error) => error && rejectWithValue(error))
);

export const addVideoHandler: any = createAsyncThunk(actions.ADD_VIDEO, (data: any, { rejectWithValue }) =>
    addVideo(data.videoUuid,data.fileName,data.userId,data.videoUpload,data.uuid).catch((error) => error && rejectWithValue(error))
);
export const addScratchVideoHandler: any = createAsyncThunk(actions.ADD_SCRATCH_VIDEO, (data: any, { rejectWithValue }) =>
    addScratchVideo(data.videoUuid,data.fileName,data.userId,data.videoUpload,data.uuid ,data.fileType, data?.getUrl).catch((error) => error && rejectWithValue(error))
);

export const getTranscriptionTextHandler: any = createAsyncThunk(actions.GET_TRANSCRIPTION_TEXT, (data: any, { rejectWithValue }) =>
    getTranscriptionTex(data).catch((error) => error && rejectWithValue(error))
);

export const deleteVideoHandler: any = createAsyncThunk(actions.DELETE_VIDEO, (data: any, { rejectWithValue }) =>
    deleteVideo(data).catch((error) => error && rejectWithValue(error))
);
export const deleteScratchDetectVideoHandler: any = createAsyncThunk(actions.DELETE_SCRATCH_VIDEO, (data: any, { rejectWithValue }) =>
    deleteScratchDetectVideo(data).catch((error) => error && rejectWithValue(error))
);

export const getDashboardDataHandler: any = createAsyncThunk(actions.GET_CUSTOM_OBJECT_PERCENT, (data: any, { rejectWithValue }) =>
    getDashboardData(data).catch((error) => error && rejectWithValue(error))
);

export const getCustomObjectsPercentHandler: any = createAsyncThunk(actions.GET_CUSTOM_OBJECT_PERCENT, (data: any, { rejectWithValue }) =>
    getCustomObjectsPercent(data).catch((error) => error && rejectWithValue(error))
);

export const getDealerVideoRatingPercentHandler: any = createAsyncThunk(actions.GET_VIDEO_RATING_PERCENT, (data: any, { rejectWithValue }) =>
    getDealerVideoRatingPercent(data).catch((error) => error && rejectWithValue(error))
);

export const getBrandVideoRatingPercentHandler: any = createAsyncThunk(actions.GET_BRAND_VIDEO_RATING_PERCENT, (data: any, { rejectWithValue }) =>
    getBrandVideoRatingPercent(data).catch((error) => error && rejectWithValue(error) )
);

export const getDealerVideosStatusPercentHandler: any = createAsyncThunk(actions.GET_BRAND_VIDEO_RATING_PERCENT, (data: any, { rejectWithValue }) =>
    getDealerVideosStatusPercent(data).catch((error) => error && rejectWithValue(error) )
);
export const retryVideoAnalysisHandler: any = createAsyncThunk(
    actions.RETRY_VIDEO_ANALYSIS,
    async (uuid: string, { rejectWithValue }) => {
        try {
            const response = await retryVideoAnalysis(uuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const retryScratchedVideoAnalysisHandler: any = createAsyncThunk(
    actions.RETRY_SCRATCHED_VIDEO_ANALYSIS,
    async (uuid: string, { rejectWithValue }) => {
        try {
            const response = await retryScratchVideoAnalysis(uuid);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBrandTotalVideoCountHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_COUNT,
    async (userUuid: string, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoCount(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getBrandTotalVideoAvgLengthHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_AVG_LENGTH,
    async (userUuid: string, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoAvgLength(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBrandTotalVideoSuccessRateHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_SUCCESS_RATE,
    async (userUuid: string, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoSuccessRate(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBrandTotalVideoFailureRateHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_FAILURE_RATE,
    async (userUuid: string, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoFailureRate(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getBrandTotalVideoNoisyRateHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_NOISY_RATE,
    async (userUuid: string, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoNoisyRate(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBrandTotalVideoScoreByBrandHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_NOISY_RATE,
    async (userUuid: string, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoScoreByBrand(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBrandTotalVideoScoreByBrandDealerHandler: any = createAsyncThunk(
    actions.GET_BRANDS_TOTAL_VIDEO_NOISY_RATE,
    async (userUuid: any, { rejectWithValue }) => {
        try {
            const response = await getBrandTotalVideoScoreByBrandDealer(userUuid);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const videoSlice = createSlice({
    name: 'videoSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(videoHandler.pending, (state, action) => {
            })
            .addCase(videoHandler.fulfilled, (state, action) => {
                state.videoData = action.payload?.data;
                state.paginationData =action.payload
            })
            .addCase(videoHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            })
            .addCase(submitVideoUrlsHandler.pending, (state, action) => {
            })
            .addCase(submitVideoUrlsHandler.fulfilled, (state, action) => {
                state.videoData = action.payload?.data;
            })
            .addCase(submitVideoUrlsHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            })
            .addCase(videoUploadToS3Handler.pending, (state, action) => {
            })
            .addCase(videoUploadToS3Handler.fulfilled, (state, action) => {
            })
            .addCase(videoUploadToS3Handler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            })
            .addCase(addVideoHandler.pending, (state, action) => {
            })
            .addCase(addVideoHandler.fulfilled, (state, action) => {
            })
            .addCase(addVideoHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            })
            .addCase(retryVideoAnalysisHandler.pending, (state, action) => {
            })
            .addCase(retryVideoAnalysisHandler.fulfilled, (state, action) => {
            })
            .addCase(retryVideoAnalysisHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            });
    },
});

export const { } = videoSlice.actions;
export default videoSlice.reducer;
