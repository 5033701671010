// DataTable.js
import React, { useEffect, useState } from "react";
import ModalComponent from "./Modal";
import { RootState } from "../pages/Store/store";
import {
  deleteVideoHandler,
  getTranscriptionTextHandler,
  getVideoDetailsHandler,
  getVideoUrlHandler,
  retryVideoAnalysisHandler,
  videoHandler,
} from "../pages/Store/slices/videoSlice";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayerModal from "./VideoPlayerModal";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ThumbnailCarousalModal from "./ThumbnailCarousalModal";
import { getThumbnailsHandler } from "../pages/Store/slices/thumbnailsSlice";
import {
  DELETE_VIDEO,
  DUMMY_THUMBNAIL_URL,
  FAILED,
  GET_THUMBNAILS,
  GET_VIDEO_TRANSCRIPTION,
  SUCCESSFUL,
} from "../common/commonConstants";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ProgressBar from "@ramonak/react-progress-bar";
import moment from "moment";
import { TbRotateClockwise2 } from "react-icons/tb";
import socket from "../socket/socket";
import { WiTime8 } from "react-icons/wi";
import { CgTimelapse } from "react-icons/cg";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { Rating } from "react-simple-star-rating";
import {
  faArrowLeft,
  faArrowRight,
  faEye,
  faRotateForward,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoTranscriptionTextModal from "./TranscriptionTextModal";
import DeleteDialogBox from "./VideoDeleteModal";
import { getDealersVideoHandler } from "../pages/Store/slices/dealerSlice";

interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const DataTable = ({ dealer, trigger }: any) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { user, selectedBrandId, selectedDealerId } = useSelector(
    (state: RootState) => state.auth
  );
  const { videoData, paginationData } = useSelector(
    (state: RootState) => state.video
  );
  const [videos, setVideos] = useState<any>([]);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
  const [paginationSize, setPaginationSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(paginationData?.currentPage);
  const [showThumbnailModal, setShowThumbnailModal] = useState(false);
  const [videoThumbnails, setVideoThumbnails] = useState<any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showVideoDeleteModal, setShowVideoDeleteModal] = useState(false);
  const [transcriptionTextTitle, setTranscriptionTextTitle] = useState("");
  const [transcriptionText, setTranscriptionText] = useState("");
  const [deleteVideoId, setDeleteVideoId] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showTranscriptionTextModal, setShowTranscriptionTextModal] =
    useState(false);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] =
    useState<PageData>({});
  const userId = user?.user?.uuid;
  const PER_PAGE = 10;
  const token = localStorage.getItem("token");
  const user_id = user?.user?.uuid ?? user?.uuid;
  useEffect(() => {
    setVideos(videoData);
    setTotalRecords(paginationData?.totalItems);
    setCurrentPage(paginationData?.currentPage);
  }, [videoData, paginationData]);
  useEffect(() => {
    const data = {
      userid: user_id,
      pageNumber,
      selectedDealer: selectedDealerId,
    };
    dispatch(getDealersVideoHandler(data)).then((res: any) => {
      if (res.type == "getDealersVideos/fulfilled") {
        if (res.payload) {
          setVideos(res.payload.data);
        }
      }
    });
    getVideoData(1, selectedDealerId);
  }, [trigger]);

  useEffect(() => {
    setPageNumber(1);
    setCurrentPage(1);
  }, [videoData, trigger]);

  const paginateData = (data: any) => {
    setVideos(data);
    const firstSet = data?.slice(0, PER_PAGE);
    setVideos(firstSet);
  };

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };
  const getVideoData = async (pageNumber = 1, dealerUuid: any) => {
    const brandName = selectedBrandId;
    const data = {
      userid: userId,
      pageNumber,
      uuid: dealerUuid,
      brandName: brandName,
    };
    await dispatch(videoHandler(data))
      .then((res: any) => {
        setVideos(res?.payload?.data);
        const videoData = res?.payload?.data;
        setTotalRecords(res?.payload?.totalItems);
        updateData(pageNumber, res?.payload?.data);
        setVideos(videoData);
        paginateData(videoData);
        setCurrentPage(0);
        setPageNumber(pageNumber);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const selectedDealerUuid = selectedDealerId ?? "0";
    getVideoData(pageNumber, selectedDealerUuid);
  }, [token]);

  const handleViewDetails = async (video: any) => {
    await dispatch(getVideoDetailsHandler(video))
      .then((res: any) => {
        setSelectedVideo(res.payload);
      })
      .catch((err: any) => {
        console.log(err, "err");
      });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenVideoModal = async (video: any) => {
    try {
      await dispatch(getVideoDetailsHandler(video))
        .then((res: any) => {
          setSelectedVideo(res.payload);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
      setShowVideoModal(true);
    } catch (error) {
      toast.error("Failed to generate video URL. Please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const shortenUrl = (url: string) => {
    const parts = url.split("?");
    const baseUrl = parts[0];
    const segments = baseUrl.split("/");
    const filename = segments[segments.length - 1];
    return filename;
  };

  const onHandleFetchMore = (selected: any) => {
    const newPageNumber = selected + 1;
    if (videos) {
      setPageNumber(newPageNumber);
    }
    getVideoData(newPageNumber, selectedDealerId);
    // if (alreadyFetchedDataPageNumber.hasOwnProperty(newPageNumber)) {
    //   setVideos(alreadyFetchedDataPageNumber[newPageNumber]);
    // } else {
    //   getVideoData(newPageNumber, selectedDealerId);
    // }
    setPageNumber(newPageNumber);
    setCurrentPage(newPageNumber);
  };

  const handleThumbnailClick = async (uuid: string) => {
    await dispatch(getThumbnailsHandler(uuid))
      .then((res: any) => {
        if (res.type == GET_THUMBNAILS) {
          if (res.payload.data.rows) {
            setVideoThumbnails(res?.payload?.data?.rows);
            setShowThumbnailModal(true);
          } else {
            toast.warning(t("thumbnails.noThumbnails"), {
              position: "top-right",
              autoClose: 2000,
            });
          }
        }
      })
      .catch((err: any) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 2000,
        });
      });
  };

  const handleRetry = async (uuid: string) => {
    await dispatch(retryVideoAnalysisHandler(uuid))
      .then(async (res: any) => {
        toast.success(t("VideoProcess.videoProcessing"), {
          position: "top-right",
          autoClose: 2000,
        });
        const brandName = selectedBrandId;
        const data = {
          userid: userId,
          pageNumber: 1,
          uuid: dealer,
          brandName: brandName,
        };

        await dispatch(videoHandler(data))
          .then((res: any) => {
            setVideos(res?.payload?.data);
            const videoData = res?.payload?.data;
            setTotalRecords(res?.payload?.totalItems);
            updateData(pageNumber, res?.payload?.data);
            setVideos(videoData);
            paginateData(videoData);
            setCurrentPage(0);
          })
          .catch((err: any) => {
            console.log("err", err);
            toast.error(err.message, {
              position: "top-right",
              autoClose: 2000,
            });
          });
      })
      .catch((err: any) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 2000,
        });
      });
  };

  socket.on("video-data-update-res", async (data: any) => {
    const updatedData = await videos?.map((e: any) => {
      let newVideo = {};
      if (data.videoUuid !== e.uuid) {
        newVideo = e;
      } else {
        newVideo = {
          uuid: data?.videoUuid ?? e?.uuid,
          key: data?.storageLink,
          progress: data?.progress ?? e?.progress,
          status: data?.status ?? e?.status,
          posterThumbnailLink: data?.thumbnail ?? e?.thumbnail,
          createdAt: e?.createdAt,
          updatedAt: e?.updatedAt,
          videoUrl: e?.storageLink,
          storageLink: data?.storageLink,
          licensePlate: data?.licensePlate ?? e?.licensePlate,
          starRating: data?.starRating ?? e?.starRating,
          totalRating: data?.totalRating,
        };
      }
      return newVideo;
    });
    setVideos(updatedData);
  });
  const toTitleCase = (str: string) => {
    if (!str) return "";
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  };

  const handleReadMore = async (uuid: string, view: string) => {
    dispatch(getTranscriptionTextHandler({ uuid, view })).then((res: any) => {
      if (res.type == GET_VIDEO_TRANSCRIPTION) {
        setIsExpanded(true);
        setShowTranscriptionTextModal(true);
        const title =
          "summary" === view
            ? t("constants.summary")
            : t("constants.transcriptionText");
        setTranscriptionTextTitle(title);
        setTranscriptionText(res.payload);
      }
    });
  };

  const handleDeleteVideo = async (uuid: string) => {
    setShowVideoDeleteModal(true);
    setDeleteVideoId(uuid);
  };

  const deleteConfirmation = (uuid: string) => {
    setLoading(true);
    const data = {
      uuid,
      userId: user_id,
    };
    dispatch(deleteVideoHandler(data)).then(async (deleteRes: any) => {
      if (deleteRes.payload.statusCode == 200) {
        const brandName = selectedBrandId;
        const data = {
          userid: userId,
          pageNumber,
          uuid: selectedDealerId,
          brandName: brandName,
        };
        let pageNo = pageNumber;
        if (videoData?.length == 1) {
          setPageNumber(pageNumber - 1);
          data.pageNumber = pageNumber - 1;
          pageNo = pageNumber - 1;
        }
        await dispatch(videoHandler(data))
          .then((res: any) => {
            setVideos(res?.payload?.data);
            const videoData = res?.payload?.data;
            setTotalRecords(res?.payload?.totalItems);
            updateData(pageNumber, res?.payload?.data);
            setVideos(videoData);
            paginateData(videoData);
            setPageNumber(pageNo);
            setCurrentPage(pageNo);
            setLoading(false);
          })
          .catch((err: any) => {
            console.log("err", err);
          });
        if (deleteRes.type == DELETE_VIDEO) {
          toast.success(t("VideoProcess.videoDelete"), {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          setLoading(false);
          toast.error(
            deleteRes?.payload?.message ?? t("VideoProcess.videoDeleteFailed"),
            {
              position: "top-right",
              autoClose: 2000,
            }
          );
        }
      } else {
        setLoading(false);
        toast.error(
          deleteRes?.payload?.message ?? t("VideoProcess.videoDeleteFailed"),
          {
            position: "top-right",
            autoClose: 2000,
          }
        );
      }
    });
  };

  const monitorObjectOfVideo = (detectedMonitoredObjectsArr: any) => {
    const allMonitoredObjects = [
      "battery",
      "brake",
      "car",
      "filter",
      "indicator",
      "license plate",
      "tire",
      "wiper",
    ];

    const detectedMonitoredKeywordValues: any = [];
    for (let detectedKeyword of detectedMonitoredObjectsArr) {
      detectedMonitoredKeywordValues.push(
        detectedKeyword.keyword.toLowerCase()
      );
    }
    const highlightedElements = allMonitoredObjects.map((item, index) => {
      // Check if the current item is in the detectedMonitoredObjectsArr
      const isHighlighted = detectedMonitoredKeywordValues.includes(item);
      var titleCaseVideoObject = "";
      if (index >= 0 && index < allMonitoredObjects.length - 1) {
        titleCaseVideoObject = toTitleCase(item) + ", ";
      } else {
        titleCaseVideoObject = toTitleCase(item);
      }
      return (
        <span
          key={index}
          style={{
            color: isHighlighted ? "#009900" : "#AA0000",
            fontWeight: isHighlighted ? "bold" : "normal",
          }}
        >
          {titleCaseVideoObject}
        </span>
      );
    });
    return <>{highlightedElements}</>;
  };

  const objectOfVideo = (detectedVideoObjectsArr: any) => {
    const allVideoObjects = [
      "car",
      "license plate",
      "brakes",
      "tires",
      "air filters",
      "indicators",
      "wiper blades",
      "brake pad gauge",
      "tire gauge",
      "dirty air filters",
      "car interior",
    ];

    let detectedVideoObjectValuesArr: any = [];
    for (let detectedVideoObject of detectedVideoObjectsArr) {
      detectedVideoObjectValuesArr.push(
        detectedVideoObject.object.toLowerCase()
      );
    }
    const highlightedElements = allVideoObjects.map((item: any, index) => {
      // Check if the current item is in the detectedVideoObjectsArr
      const isHighlighted = detectedVideoObjectValuesArr.includes(item);
      var titleCaseVideoObject = "";
      if (index >= 0 && index < allVideoObjects.length - 1) {
        titleCaseVideoObject = toTitleCase(item) + ", ";
      } else {
        titleCaseVideoObject = toTitleCase(item);
      }
      return (
        <span
          key={index}
          style={{
            color: isHighlighted ? "#009900" : "#AA0000",
            fontWeight: isHighlighted ? "bold" : "normal",
          }}
        >
          {titleCaseVideoObject}
        </span>
      );
    });
    return <>{highlightedElements}</>;
  };

  // Extract common nouns from videoTranscriptCommonKeyword
  const commonNouns =
    selectedVideo?.videoTranscript?.videoTranscriptCommonKeyword || [];
  const commonNounKeywords = commonNouns
    .map((keyword: any) => keyword.keyword)
    .join(", ");

  return (
    <div>
      <TableContainer component={Paper} className="custom-table mb-5">
        <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "#3483c8", fontWeight: "bold" }}
              >
                Unique ID
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#3483c8",
                  fontWeight: "bold",
                  padding: "0 8px",
                }}
              >
                Video URL
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#3483c8",
                  fontWeight: "bold",
                  padding: "0 8px",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  padding: "0 8px",
                }}
              >
                {t("constants.progress")}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  padding: "0 8px",
                }}
              >
                {t("constants.videoQualityRating")}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  padding: "0 8px",
                }}
              >
                {t("constants.licensePlate")}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  padding: "0 8px",
                }}
              >
                {t("constants.updatedAt")}
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}
              >
                {t("constants.action")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos && videos.length > 0 ? (
              videos?.map((row: any) => {
                return (
                  <TableRow key={row.uuid} className="mb-4">
                    <TableCell sx={{ padding: "8px" }}>
                      <div className="d-flex align-items-center gap-3">
                        <div
                          onClick={() => {
                            handleThumbnailClick(row?.uuid);
                          }}
                        >
                          {row?.posterThumbnailLink ? (
                            <img
                              className="thumbnails"
                              src={
                                row?.posterThumbnailLink ?? DUMMY_THUMBNAIL_URL
                              }
                              title={t("thumbnails.videoThumbnail")}
                              onError={(e: any) => {
                                e.target.src = DUMMY_THUMBNAIL_URL; // Replace with your alternate image URL
                              }}
                            />
                          ) : (
                            <img
                              className="thumbnails"
                              src={DUMMY_THUMBNAIL_URL}
                              title={t("thumbnails.videoThumbnail")}
                            ></img>
                          )}
                        </div>
                        <div>{row.uuid}</div>
                      </div>
                    </TableCell>
                    <TableCell sx={{ padding: "0 8px" }}>
                      <a
                        href="#"
                        className="link-color"
                        onClick={() => handleOpenVideoModal(row?.uuid)}
                      >
                        {/* {row.storageLink ? row.storageLink : row?.storageLink?.split('/')[row?.videoUrl?.split('/')?.length - 1]} */}
                        {
                          row?.storageLink
                            ?.split("/")
                            [row?.storageLink.split("/").length - 1]?.split(
                              "?"
                            )[0]
                        }
                      </a>
                    </TableCell>
                    <TableCell sx={{ padding: "0 8px" }}>
                      {toTitleCase(row?.status)}
                    </TableCell>
                    <TableCell sx={{ padding: "0 8px" }}>
                      <div className="d-flex align-items-center gap-3">
                        <ProgressBar
                          bgColor="#3483c8"
                          completed={row?.progress}
                          className="status-progressbar"
                        />
                        {row?.status !== SUCCESSFUL &&
                          row?.status !== FAILED && (
                            <TbRotateClockwise2
                              className="rotate-icon"
                              size={24}
                            />
                          )}
                      </div>
                    </TableCell>
                    <TableCell sx={{ padding: "0 8px" }}>
                      <Rating
                        fillColor="#3483c8"
                        readonly
                        allowFraction
                        size={25}
                        initialValue={row?.starRating}
                      />
                      <span style={{ marginLeft: "8px", fontSize: "10px" }}>{`${
                        row?.totalRating ?? row?.qualityScore
                      }/100`}</span>
                    </TableCell>
                    <TableCell sx={{ padding: "0 8px" }}>
                      {row?.licensePlate ? row?.licensePlate : "N/A"}
                    </TableCell>
                    <TableCell sx={{ padding: "0 8px" }}>
                      {moment(row?.updatedAt).format("DD-MM-YYYY hh:mm:ss")}
                    </TableCell>
                    <TableCell sx={{ padding: "8px" }}>
                      <div className="d-flex align-items-center justify-content-center gap-3">
                        <Tooltip
                          placement="top"
                          title={t("constants.viewDetails")}
                        >
                          <button
                            className="btn p-0"
                            onClick={() => handleViewDetails(row.uuid)}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              className="fs-6 text-primary"
                            />
                          </button>
                        </Tooltip>
                        {row.status == FAILED && (
                          <Tooltip placement="top" title={t("constants.retry")}>
                            <button
                              className="btn p-0"
                              onClick={() => {
                                handleRetry(row.uuid);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faRotateForward}
                                className="fs-6"
                              />
                            </button>
                          </Tooltip>
                        )}
                        <Tooltip placement="top" title={t("constants.delete")}>
                          <button
                            className="btn p-0"
                            onClick={() => {
                              handleDeleteVideo(row.uuid);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              color="red"
                              className="fs-6"
                            />
                          </button>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  No videos found
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showThumbnailModal && videoThumbnails?.length > 0 && (
        <ThumbnailCarousalModal
          isOpen={showThumbnailModal}
          onClose={() => setShowThumbnailModal(false)}
          thumbnails={videoThumbnails}
        />
      )}

      {showTranscriptionTextModal && isExpanded && (
        <VideoTranscriptionTextModal
          isOpen={isExpanded}
          title={transcriptionTextTitle}
          text={transcriptionText}
          onClose={() => {
            setShowTranscriptionTextModal(false);
            setIsExpanded(false);
          }}
        />
      )}
      {showVideoDeleteModal && (
        <DeleteDialogBox
          show={showVideoDeleteModal}
          clickOk={() => {
            deleteConfirmation(deleteVideoId);
            setShowVideoDeleteModal(false);
          }}
          clickCancel={() => {
            setShowVideoDeleteModal(false);
          }}
          color={"btn-danger"}
          btncancel={t("constants.cancel")}
          btnyes={t("constants.delete")}
          deleteText={t("VideoProcess.videoDeleteConfirmation")}
        />
      )}
      <ModalComponent
        isOpen={showModal}
        onClose={handleCloseModal}
        modalHeading={t("VideoProcess.videoObjectDetails")}
      >
        {selectedVideo && (
          <>
            <div className="table-responsive">
              <table className="video-details-table mt-4 mb-4">
                <tbody>
                  <tr>
                    <th>{t("constants.objects")}</th>
                    <th>{t("constants.metrics")}</th>
                  </tr>
                  <tr>
                    <td>{t("constants.uniqueID")}</td>
                    <td>{selectedVideo.uuid}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.videoUrl")}</td>
                    <td>
                      {" "}
                      <a
                        href="#"
                        onClick={() => {
                          setShowModal(false);
                          handleOpenVideoModal(selectedVideo.uuid);
                        }}
                      >
                        {
                          selectedVideo?.storageLink
                            ?.split("/")
                            [
                              selectedVideo?.storageLink.split("/").length - 1
                            ]?.split("?")[0]
                        }
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.videoLength")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.length ? <WiTime8 /> : ""}{" "}
                      {selectedVideo?.videoDetail?.length
                        ? `${selectedVideo?.videoDetail?.length}  ${t(
                            "constants.seconds"
                          )}`
                        : selectedVideo?.videoDetail?.length}{" "}
                    </td>
                  </tr>
                  <tr>
                    {<td>{t("constants.licensePlate")}</td>}
                    {
                      <td>
                        {selectedVideo?.licensePlate
                          ? selectedVideo?.licensePlate
                          : "N/A"}
                      </td>
                    }
                  </tr>
                  <tr>
                    {<td>{t("constants.deaderName")}</td>}
                    {
                      <td>
                        {selectedVideo?.videoDetail?.detectedCarBrand
                          ? selectedVideo?.videoDetail?.detectedCarBrand
                          : "N/A"}
                      </td>
                    }
                  </tr>
                  <tr>
                    {<td>{t("constants.carModelName")}</td>}
                    {
                      <td>
                        {selectedVideo?.videoDetail?.detectedCarModel
                          ? selectedVideo?.videoDetail?.detectedCarModel
                          : "N/A"}
                      </td>
                    }
                  </tr>
                  <tr>
                    <td>{t("constants.status")}</td>
                    <td>
                      {" "}
                      {toTitleCase(selectedVideo?.status)} &nbsp;
                      {selectedVideo?.status === FAILED && (
                        <Tooltip
                          placement="top"
                          title={
                            t("constants.retry") +
                              ": " +
                              selectedVideo?.reason || ""
                          }
                        >
                          <button
                            className="btn p-0"
                            onClick={() => {
                              handleRetry(selectedVideo?.uuid);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRotateForward}
                              className="fs-6"
                            />
                          </button>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.progress")}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <ProgressBar
                          bgColor="#3483c8"
                          completed={selectedVideo?.progress}
                          className="status-progressbar w-100"
                        />
                        {selectedVideo?.status !== SUCCESSFUL &&
                          selectedVideo?.status !== FAILED && (
                            <TbRotateClockwise2
                              className="rotate-icon"
                              size={24}
                            />
                          )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.videoQualityRating")}</td>
                    <td>
                      <Rating
                        fillColor="#3483c8"
                        readonly
                        allowFraction
                        size={25}
                        initialValue={selectedVideo?.qualityScore}
                      />
                      <span style={{ marginLeft: "8px", fontSize: "10px" }}>{`${
                        selectedVideo?.qualityScore * 20
                      } out of 100`}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.fps")}</td>
                    <td>{selectedVideo?.videoDetail?.fps}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.videoCodec")}</td>
                    <td>{selectedVideo?.videoDetail?.videoCodec}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.audioCodec")}</td>
                    <td>{selectedVideo?.videoDetail?.audioCodec}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.totalWords")}</td>
                    <td>{selectedVideo?.videoDetail?.totalWords}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.wordsPerMinute")}</td>
                    <td>{selectedVideo?.videoDetail?.wordsPerMinute}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.longestSilence")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.longestSilence ? (
                        <CgTimelapse />
                      ) : (
                        ""
                      )}{" "}
                      {selectedVideo?.videoDetail?.longestSilence
                        ? `${selectedVideo?.videoDetail?.longestSilence} ${t(
                            "constants.seconds"
                          )}`
                        : selectedVideo?.videoDetail?.longestSilence}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.voiceVolume")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.voiceVolume
                        ? `${selectedVideo?.videoDetail?.voiceVolume} ${t(
                            "constants.decibel"
                          )}`
                        : selectedVideo?.videoDetail?.voiceVolume}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.loudNoises")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.loudVoice
                        ? `${selectedVideo?.videoDetail?.loudVoice} ${t(
                            "constants.decibel"
                          )}`
                        : selectedVideo?.videoDetail?.loudVoice}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.summary")}</td>
                    <td>
                      <Tooltip placement="top" title={t("constants.summary")}>
                        <button
                          className="btn p-0"
                          onClick={() =>
                            handleReadMore(selectedVideo.uuid, "summary")
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="fs-6 text-primary"
                          />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.transcriptionText")}</td>
                    <td>
                      <Tooltip
                        placement="top"
                        title={t("constants.transcriptionText")}
                      >
                        <button
                          className="btn p-0"
                          onClick={() =>
                            handleReadMore(selectedVideo.uuid, "transcript")
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="fs-6 text-primary"
                          />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.monitoredKeywordsFound")}</td>
                    <td>
                      {selectedVideo?.monitoredKeywords &&
                        selectedVideo?.monitoredKeywords?.length > 0 &&
                        monitorObjectOfVideo(selectedVideo?.monitoredKeywords)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t("constants.videoObjects")}</strong>
                    </td>
                    <td>
                      <div className="table-alice-carousel">
                        <Swiper
                          navigation={true}
                          modules={[Navigation]}
                          className="mySwiper"
                          spaceBetween={34}
                          slidesPerView={3}
                          loop={true}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper: any) => console.log(swiper)}
                        >
                          {selectedVideo?.thumbnails?.map(
                            (thumbnail: any, index: any) => {
                              return (
                                <SwiperSlide key={index}>
                                  <div
                                    className="w-100"
                                    onClick={() => {
                                      handleThumbnailClick(selectedVideo?.uuid);
                                    }}
                                  >
                                    <img
                                      className="thumbnails w-100"
                                      src={thumbnail?.storageLink}
                                      alt=""
                                      title={t("thumbnails.videoThumbnail")}
                                      onError={(thumbnail: any) => {
                                        thumbnail.target.src =
                                          DUMMY_THUMBNAIL_URL; // Replace with your alternate image URL
                                      }}
                                    />
                                  </div>
                                </SwiperSlide>
                              );
                            }
                          )}
                        </Swiper>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.customObjects")}</td>
                    <td>
                      {selectedVideo?.object &&
                        selectedVideo?.object.length > 0 &&
                        objectOfVideo(selectedVideo?.object)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.commonNoun")}</td>
                    <td>
                      {commonNounKeywords && commonNounKeywords.length > 0 ? (
                        <>{commonNounKeywords}</>
                      ) : (
                        <>{"N/A"}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.video")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.videoDisplay ? (
                        <FaCheck style={{ color: "#0A0" }} />
                      ) : (
                        <FaXmark style={{ color: "#A00" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.profanity")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.profanity ? (
                        <FaCheck style={{ color: "#A00" }} />
                      ) : (
                        <FaXmark style={{ color: "#0A0" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.audioMuffled")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.audioMuffled ? (
                        <FaCheck style={{ color: "#A00" }} />
                      ) : (
                        <FaXmark style={{ color: "#0A0" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("constants.languages")}</td>
                    <td>{selectedVideo?.videoDetail?.lang}</td>
                  </tr>
                  <tr>
                    <td>{t("constants.subtitles")}</td>
                    <td>
                      {selectedVideo?.videoDetail?.subtitle ? (
                        <FaCheck style={{ color: "#0A0" }} />
                      ) : (
                        <FaXmark style={{ color: "#A00" }} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </ModalComponent>
      <VideoPlayerModal
        isOpen={showVideoModal}
        onClose={handleCloseVideoModal}
        video={selectedVideo}
        isProcessedLink={false}
      />
      <div className="d-flex align-items-center justify-content-end">
        {videos && videos?.length > 0 && (
          <ReactPaginate
            pageCount={Math.ceil(totalRecords / paginationSize)}
            previousLabel={
              <FontAwesomeIcon icon={faArrowLeft} className="text-black" />
            }
            nextLabel={
              <FontAwesomeIcon icon={faArrowRight} className="text-black" />
            }
            onPageChange={({ selected }) => onHandleFetchMore(selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
          />
        )}
      </div>
    </div>
  );
};

export default DataTable;
