import React from 'react';
import ModalComponent from './Modal';
import { Modal } from "react-bootstrap";

interface VideoTranscriptionTextModalProps {
    isOpen: boolean;
    title: string;
    text: string;
    onClose: () => void;
}

const VideoTranscriptionTextModal: React.FC<VideoTranscriptionTextModalProps> = ({ isOpen, title, text, onClose }) => {
    return (
        <ModalComponent isOpen={isOpen} onClose={onClose} modalHeading={title}>
            <Modal.Body>
                <div className=''>
                    <p className='break-word'>{text}</p>
                </div>
            </Modal.Body>
        </ModalComponent>
    );
};

export default VideoTranscriptionTextModal;
