import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {  getUserDetailsByUuidHandler } from '../../pages/Store/slices/userSlice';
import { ICreateUser, Language } from '../../types/user.type';
import { languages } from '../../common/common';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ShowUsersDetails = () => {
    const dispatch = useDispatch();
    const { uuid } = useParams()
    const navigate = useNavigate()
    const [editUserData, setEditUserData] = useState<ICreateUser>();
    const [loader, setLoader] = useState<boolean>(false);

    const getUserByUuids = async () => {
        setLoader(true)
        await dispatch(getUserDetailsByUuidHandler(uuid)).then((response: any) => {
            if (response.type === 'getUserDetailsByUuid/fulfilled') {
                const { fName, lName, email, lang, contact, status } = response?.payload?.data?.data;
                const language = languages.find((item: Language) => item.value === lang)?.label || ''
                const initialValues: ICreateUser = {
                    firstName: fName,
                    lastName: lName,
                    email: email,
                    status: status,
                    contact: contact,
                    lang: language,
                };
                setEditUserData(initialValues)
            }
        })
        setLoader(false)
    }



    useEffect(() => {
        if (uuid !== '') {
            getUserByUuids()
        }
    }, [uuid]);

    return (

        <div className="container-fluid">
            <div className="mt-5 mb-4 pb-2">
            <Header />
            </div>
            <div className='d-flex justify-content-between my-4 px-5 '>
                <div>
                    <button className='btn btn-warning rounded-4' onClick={() => navigate('/admin/dashboard')}> <FontAwesomeIcon icon={faArrowLeft} />Back</button>
                </div>
                </div>
            <div className="container-fluid d-flex justify-content-center">

                {loader ?
                    <div className="d-flex align-items-center justify-content-center" style={{ height: "200px" }}>
                        <Spinner variant='primary' />
                    </div>
                    :

                    <div className="card border-0 shadow w-50 ">
                        <div className="card-body">
                            <table className='table table-borderless table-middle fw-bold'>
                                <tbody className='user-details-tbody'>
                                    <tr >
                                        <td >FIRST NAME</td>
                                        <td className='hstack gap-3'>: <span>{editUserData?.firstName}</span></td>
                                    </tr>
                                    <tr>
                                        <td>LAST NAME</td>
                                        <td className='hstack gap-3'>: <span>{editUserData?.lastName ?? '-' }</span></td>
                                    </tr>
                                    <tr>
                                        <td>EMAIL</td>
                                        <td className='hstack gap-3'>: <span>{editUserData?.email}</span></td>
                                    </tr>
                                    <tr>
                                        <td>CONTACT</td>
                                        <td className='hstack gap-3'>: <span>{editUserData?.contact ?editUserData?.contact : "-" }</span></td>
                                    </tr>
                                    <tr>
                                        <td>STATUS</td>
                                        <td className='hstack gap-3'>: <span>{editUserData?.status}</span></td>
                                    </tr>
                                    <tr>
                                        <td>LANGUAGE</td>
                                        <td className='hstack gap-3'>: <span>{editUserData?.lang}</span></td>
                                    </tr>
                                    <tr>
                                        <td>BRANDS & DEALERS</td>
                                        <td className='hstack gap-3'>: <button className="btn btn-primary rounded-4 " onClick={() => navigate(`/manage/brands-dealers/${uuid}`)}>Manage Brands & Dealers</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

            </div>
        </div>

    )
}




export default ShowUsersDetails
