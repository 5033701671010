import axios from 'axios';

export const api = axios.create({
    timeout: 180000
});

api.interceptors.request.use(
    (req: any) => {
        // Add configurations here
        return req;
    },
    (err: any) => {
        if (err.response.status === 401) {
        } else {
            return Promise.reject(err);
        }
    }
);

// For POST requests
api.interceptors.response.use(
    (res: any) => {
        if (res.status === 201) {
        }
        return res;
    },
    (err: any) => {
        if (err.response.status === 401) {
        } else {
            return Promise.reject(err);
        }
    }
);