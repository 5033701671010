import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../Store/store';
import DataTable from '../../components/DataTable';
import VideoUpload from '../../components/VideoUpload';
import { logoutHandler, userHandler, userSelectedDealerHandler } from '../Store/slices/authSlice';
import { toast } from 'react-toastify';
import { getCustomObjectsPercentHandler, submitVideoUrlsHandler, videoHandler } from '../Store/slices/videoSlice';
import VideoUrlModalComponent from '../../components/videoUrlModal';
import { getAllDealersHandler } from '../Store/slices/dealerSlice';
import Header from '../../components/Header';

const Videos = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user, selectedDealerId, selectedBrandId } = useSelector((state: RootState) => state.auth);
  const { dealers } = useSelector((state: RootState) => state.dealers);
  const token = localStorage.getItem('token')
  const [videoUrls, setVideoUrls] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const user_id = user?.user?.uuid ?? user?.uuid;
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDealerUuid, setSelectedDealerUuid] = useState<any>('');
  const user_email = user?.user?.email
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const dealerUuid = selectedDealerId ?? '0';
    setSelectedDealerUuid(dealerUuid);
    const brandName = selectedBrandId ?? '';
    dispatch(getAllDealersHandler(brandName)).then((res: any) => {
      if ('0' === dealerUuid && res.payload) {
        dispatch(userSelectedDealerHandler(res?.payload[0]?.uuid))
        setSelectedDealerUuid(res.payload[0]?.uuid);
      }
    })
  }, []);


  // useEffect(() => {
  //   if (!token) {
  //     navigate("/signin");
  //   }
  // }, [token, navigate]);

  const isValidUrl = (url: string) => {
    const videoUrlRegex = /^(https?:\/\/)?((www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=|embed\/|v\/|.+\?v=)?[a-zA-Z0-9_-]{11}|vimeo\.com\/\d+|([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}\/.*\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv))$/i;
    return videoUrlRegex.test(url);
  };

  useEffect(() => {
    if (videoUrls.trim() === "") { // Check if videoUrls is empty
      setError(false);
      setErrorMsg(""); // Clear error message if videoUrls is empty
      return;
    }

    const urlsArray = videoUrls.split(',').map(url => url.trim()).filter(url => url !== "");

    if (urlsArray.length > 10) {
      setError(true);
      setErrorMsg(t('VideoProcess.videoUrlCountValidation'));
    } else if (urlsArray.some(url => !isValidUrl(url))) {
      setError(true);
      setErrorMsg(t('VideoProcess.videoUrlInvalidValidation'));
    }
    //commented for future use
    // else if (!videoUrls.includes(',')) { // Check if comma and space are present
    //   setError(true);
    //   setErrorMsg("Please separate URLs with a comma and space.");
    // } 
    else {
      setError(false);
      setErrorMsg(""); // Clear error if the number of URLs is 10 or less and all URLs are valid
    }

  }, [videoUrls]);

  useEffect(() => {
    const dealerUuid = selectedDealerId ?? '0';
    const data = {
      userid: user_id,
      uuid: dealerUuid
    }
    dispatch(getCustomObjectsPercentHandler(data)).then((res: any) => {
    })
  }, [trigger])

  const handleLogout = () => {
    sessionStorage.clear()
    localStorage.removeItem('token')
    dispatch(logoutHandler());
    dispatch(userHandler(false))
    navigate("/");
  };

  const handleCloseVideoModal = () => {
    setShowModal(false);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true); // Open the confirmation modal
  };
  const handleConfirm = async () => {
    setShowModal(false);
    setLoading(true);
    const urlsArray = videoUrls.split(',').map(url => url.trim());
    let urlfinalArr =[]
    let newurlarr = urlsArray.sort((a,b)=>{return (b.length-a.length)})
    for(let url of newurlarr){
      if(url.length>0){
        urlfinalArr.push(url)
      }
    }
    const data = {
      userId: user_id,
      urlsArray:urlfinalArr,
      uuid: selectedDealerId
    };
    await dispatch(submitVideoUrlsHandler(data)).then(async (res: any) => {
      setLoading(false);
      if (res.type == "submitVideoUrls/fulfilled" && res.payload != "") {
        const data = {
          userid: user_id,
          pageNumber: 1,
          uuid: selectedDealerId,
          brandName: selectedBrandId
        };

        await dispatch(videoHandler(data));
        toast.success(t('VideoProcess.videoUrlSuccess'), {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        toast.error(t('VideoProcess.videoUrlFail'), {
          position: "top-right",
          autoClose: 2000,
        });
      }

    }).catch((err: any) => {
      toast.error(t('VideoProcess.videoUrlFail'), {
        position: "top-right",
        autoClose: 2000,
      });
    });
    // Clear the textarea
    setVideoUrls("");
  };

  const setTriggerfn = (data: any) => {
    setTrigger(true);
    setTimeout(() => {
      setTrigger(false);
    }, 500);
    const dealerUuid = selectedDealerId ?? '0';
    setSelectedDealerUuid(dealerUuid);
  }

  return (
    <div className="min-vh-100 container-fluid">
      <div className='px-lg-5 mx-lg-5'>
        <div className="mt-5 mb-4 pb-2">
          <Header setTrigger={setTriggerfn} />
        </div>

        <div className='row gy-4 mb-5'>
          <div className="col-lg-6">
            <div className='upload-area'>
              <VideoUpload dealerId={selectedDealerId} />
            </div>
          </div>
          <div className="col-lg-6">
            <form onSubmit={handleSubmit} className="submit-url-area">
              <div className='flex-fill'>
                <textarea
                  id="video_urls"
                  name="video_urls"
                  placeholder={t("Dashboard.enterUrl")}
                  className="form-control submit-url-textarea"
                  rows={5}
                  value={videoUrls}
                  onChange={(e) => setVideoUrls(e.target.value)}
                />
                {error && <div className="error-message" style={{ color: 'red' }}>{errorMsg}</div>}
              </div>
              <div className='text-end'>
                <button type='submit' disabled={error || videoUrls.trim() == ""} className='btn btn-primary custom-btn'>Submit Urls</button>
              </div>
            </form>
          </div>
        </div>
        <div className="table-container">
          <DataTable dealer={selectedDealerId} trigger={trigger} />
        </div>
        <VideoUrlModalComponent
          isOpen={showModal}
          onClose={handleCloseVideoModal}
          modalHeading={"Video Urls"}
          onSubmit={handleConfirm}
        >
          {videoUrls.split(',').map(url => url.trim()).filter(url => url !== "")}
        </VideoUrlModalComponent>
      </div>
    </div>
  );
};

export default Videos;
