import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
    forgotPasswordHandler,
} from "../../Store/slices/authSlice";
import { TextField } from "@mui/material";
import { RootState } from "../../Store/store";
import { Spinner } from "react-bootstrap";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { forgotPasswordIsLoading } = useSelector((state: RootState) => state.auth)


    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .email("Please enter valid email address")
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const data = {
                email: values?.email
            }
            try {
                await dispatch(forgotPasswordHandler(data)).then((result: any) => {
                    if (result?.payload?.statusCode === 404) {
                        toast.error(result?.payload?.message, {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                    }
                    else if (result?.payload?.statusCode === 201) {
                        toast.success(result?.payload?.message, {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                        resetForm();
                        navigate('/signin')
                    }
                }).catch((error: any) => {
                    console.log(error)
                })
            } catch (error) {
                console.error(error, "error");
            }

        },
    });

    return (
        <>
            <div className="vh-100 bg-primary-light">
                <div className="container vh-100">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-8 col-xl-6 hstack align-items-center justify-content-center">
                            <div className="card border-0 mb-5 shadow w-100 p-4">
                                <div className="card-body">
                                    <div className="fs-4 fw-semibold mb-5 text-center hstack gap-3">
                                        <span>Forgot Password</span>
                                    </div>
                                    <form onSubmit={formik.handleSubmit} className="vstack gap-4">

                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            size="small"
                                            variant="standard"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                        <div>
                                            <button type="submit" className="btn btn-primary rounded-4 px-4 py-2 w-100" disabled={forgotPasswordIsLoading}> {forgotPasswordIsLoading && <Spinner animation={'border'} size='sm' />}{"Send Verification Email"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword
