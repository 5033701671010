import React, { useEffect, useState } from 'react';
import { DatatableWrapper, Filter, TableBody, TableHeader } from 'react-bs-datatable';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DialogBox from '../../components/DeleteBox';
import Header from '../../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store/store';
import { deleteUserHandler, editUserByIdHandler, getAllUserHandler } from '../Store/slices/userSlice';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash, faUserPlus, faArrowLeft, faArrowRight,  faClipboardList } from '@fortawesome/free-solid-svg-icons';

const UserDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterValue, setFilterValue] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
    const [pageNumber, setPageNumber] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [startItem, setStartItem] = useState(1);
    const [endItem, setEndItem] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const { users } = useSelector((state: RootState) => state.users);
    const [userDeleteID, setUserDeleteID] = useState('');
    const [currentUserfName, setCurrentUserfName] = useState("");
    const { isAdmin } = useSelector((state: RootState) => state.auth);
    const [totalRecord, setTotalRecord] = useState(0);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);


    useEffect(() => {
        if (!isAdmin) {
            navigate('/dashboard');
        }
    }, []);

    const getUserData = async (isDelete = false) => {
        const trimmedFilterValue = filterValue.trim();
        // Check if filterValue contains only spaces
        if (trimmedFilterValue.length === 0 && filterValue.length > 0) {
            // If filterValue contains only spaces, do not call the API
            return;
        }
        const searchVal = filterValue.trim().length === 0 ? "" : filterValue.trim().replace(/\s+/g, ' ');
        const sortColumnProp = sortColumn.prop;
        const sortColumnOrder = sortColumn.order;

        const data = {
            pageLimit: pageNumber + 1, perPage, searchVal: searchVal, sortColumn: sortColumnProp, sortType: sortColumnOrder
        };

        const userDataList = await dispatch(getAllUserHandler(data));
        if (userDataList?.payload?.currentPage !== undefined) {
            setPageNumber(userDataList?.payload?.currentPage - 1);
            setTotalRecord(userDataList?.payload?.totalItems);

            if (userDataList?.payload.currentPage == userDataList?.payload.totalPages) {
                setIsLastPage(true)
            } else {
                setIsLastPage(false)
            }
            if (userDataList?.payload.currentPage == 1) {
                setIsFirstPage(true)
            } else {
                setIsFirstPage(false)
            }
            if (isDelete && userDataList?.payload?.currentPage > 1 && userDataList?.payload?.dataLength === 0) {
                handlePageClick({ selected: userDataList?.payload?.currentPage - 2 });
            }
        } else {
            setPageNumber(prev => prev);
        }
    };

    //useEffect starts here
    useEffect(() => {
        getUserData();
    }, [perPage, pageNumber, sortColumn, filterValue]);

    useEffect(() => {
        totalrecordHandle();
    }, [startItem, endItem, totalRecord, users]);

    const totalrecordHandle = () => {
        let totalRecordTemp = startItem - 1 + perPage;
        if (totalRecordTemp > totalRecord) {
            totalRecordTemp = totalRecord;
        }
        setEndItem(totalRecordTemp);
    };

    const handlePageClick = ({ selected }: { selected: number }) => {
        setPageNumber(selected);
        setStartItem(selected * perPage + 1);
        totalrecordHandle();
    };

    const deleteConfirmHandler = (uuid: any, fName: any) => {
        setShowDelete(true);
        setUserDeleteID(uuid);
        setCurrentUserfName(fName);
    };

    const deleteUser = async (uuid: String) => {
        await dispatch(deleteUserHandler(uuid)).then((response: any) => {
            if (response?.payload?.status === 200) {
                getUserData(true);
                toast.success("User Deleted", {
                    position: "top-right",
                    autoClose: 2500,
                });
            }
        });
    };

    const setTriggerfn = (data: any) => {
        setTrigger(true);
        setTimeout(() => {
            setTrigger(false);
        }, 500);
    };

    // Define table headers
    const header = [
        {
            title: 'Uuid',
            prop: "uuid",
        },
        {
            title: 'First Name',
            prop: "fName",
            isSortable: true,
            isFilterable: true,
        },
        {
            title: 'Email',
            prop: "email",
            isSortable: true,
            isFilterable: true,
        },
        {
            title: "Actions",
            prop: "status",
            thProps: {
                className: "text-end"
            },
            cell: (row: any) => (
                <div className="hstack justify-content-end gap-2">
                     <IconButton className='size-34' aria-label="edit-brands-dealers" color="warning" >
                        <FontAwesomeIcon icon={faClipboardList} size='xs' onClick={()=>{navigate(`/manage/brands-dealers/${row.uuid}`)}} />
                    </IconButton>
                    {/* commented for future use */}
                    <IconButton className='size-34' aria-label="edit" color="success" onClick={() => { dispatch(editUserByIdHandler(row.uuid)); navigate('/admin/edit-user') }}>
                        <FontAwesomeIcon icon={faPen} size='xs' />
                    </IconButton>
                    <IconButton className='size-34' aria-label="view" color="primary" onClick={()=>{ navigate(`/show-user-details/${row.uuid}`)}}>
                        <FontAwesomeIcon icon={faEye} size='xs' />
                    </IconButton>

                    <IconButton className='size-34' aria-label="delete" color="error" onClick={() => { deleteConfirmHandler(row.uuid, row.fName) }}>
                        <FontAwesomeIcon icon={faTrash} size='xs' />
                    </IconButton>
                    {/* commented for future use */}
                 
                   
                
                </div>
            ),
        },
    ];

    const openCreateUser = () => {
        dispatch(editUserByIdHandler(''))
        navigate('/admin/create-user')
    }

    return (
        <div className="container-fluid">
            <div className="mt-5 mb-4 pb-2">
                <Header setTrigger={setTriggerfn} />
            </div>
            <div className="users">
                <div className="row">
                    <div className="col-12">
                        <DatatableWrapper body={users} headers={header}>
                            <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap mb-3">
                                <button
                                    onClick={() => openCreateUser()}
                                    className="btn btn-primary hstack gap-2 rounded-4 px-3 mw-120"
                                >
                                    <span>Create Users</span>
                                    <FontAwesomeIcon icon={faUserPlus} />
                                </button>
                                <Filter
                                    classes={{
                                        inputGroup: 'w-auto filter-options',
                                        input: ""
                                    }}
                                    placeholder={"Search by Name/Email"}
                                    controlledProps={{
                                        filter: filterValue,
                                        onFilterChange: (value) => {
                                            setFilterValue(value);
                                            setPageNumber(0);
                                        },
                                    }}
                                />
                            </div>
                            <div className="table-responsive custom-table table-middle mb-5">
                                <table className="table mb-0">
                                    <TableHeader
                                        controlledProps={{
                                            onSortChange: setSortColumn,
                                            sortState: sortColumn,
                                        }}
                                    />
                                    {users.length === 0 ? (
                                        <tbody className="tbody"><tr className="tbody-tr"><td className="tbody-td" colSpan={header.length}><span>No records found</span></td></tr></tbody>
                                    ) : (
                                        <>
                                            <TableBody />
                                        </>
                                    )}
                                </table>
                            </div>
                            <div className="d-flex justify-content-end">
                                <ReactPaginate
                                    pageCount={Math.ceil(totalRecord / perPage)}
                                    previousLabel={<FontAwesomeIcon icon={faArrowLeft} className={`text-black-${isFirstPage ? 'disabled' : ''}`} />}
                                    nextLabel={<FontAwesomeIcon icon={faArrowRight} className={`text-black-${isLastPage ? 'disabled' : ''}`} />}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={pageNumber}
                                    disableInitialCallback={true}
                                    initialPage={pageNumber}
                                />
                            </div>
                        </DatatableWrapper>
                    </div>
                </div>
            </div>
            <DialogBox
                show={showDelete}
                clickOk={() => {
                    deleteUser(userDeleteID);
                    setShowDelete(false);
                }}
                clickCancel={() => { setShowDelete(false); setUserDeleteID('') }}
                btncancel={"Cancel"}
                btnyes={"Delete"}
                question={`Are you sure you want to delete user ${currentUserfName}?`}
            />
        </div>
    );
};

export default UserDashboard;
