import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import Logo from "../assets/images/logo.png"

interface AuthWrapperProps {
    title: string
    icon: any
    children: any
}
const AuthWrapper: FC<AuthWrapperProps> = ({ title, icon, children }) => {
    return (
        <div className="vh-100 bg-primary-light vstack justify-content-center">
            <div className="container auth-container">
                <div className="row justify-content-center justify-content-xl-start">
                    <div className="col-md-8 col-xl-6 d-block d-xl-flex flex-column justify-content-center pb-1 mb-4 pb-xl-0 mb-xl-0">
                        <div className="d-flex justify-content-center text-center mx-3 ms-xl-0 pe-xl-4 me-xl-5">
                            <div className="logo">
                                <img src={Logo} alt="Logo" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-xl-6">
                        <div className="mx-2 mx-lg-0">
                            <div className="card border-0 shadow w-100 p-4">
                                <div className="card-body">
                                    <div className="fs-4 fw-semibold mb-5 text-center hstack gap-3">
                                        <span>{title}</span>
                                        <FontAwesomeIcon icon={icon} className="text-primary" />
                                    </div>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthWrapper