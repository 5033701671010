import React, { useState } from 'react';
import ModalComponent from './Modal';

interface VideoPlayerModalProps {
  isOpen: boolean;
  onClose: () => void;
  video: any;
  isProcessedLink?: boolean;
}

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({ isOpen, onClose, video, isProcessedLink }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  let NewUrl = ''
  if(video){
  if (isProcessedLink) {
    NewUrl = video.processedLink
  } else {
    NewUrl = video.storageLink
  }}
  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsDownloading(true);
    fetch(NewUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${video.uuid}.mp4` || 'video.mp4';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Download failed:', error))
      .finally(() => setIsDownloading(false));
  };
  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      {video && (
        <div className="video-player-modal mt-4 mb-4">
          <video controls width="100%">
            <source src={NewUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="download-button-container">
            <a
              href={NewUrl}
              onClick={handleDownload}
              className={`btn btn-primary ${isDownloading ? 'disabled' : ''}`}
            >
              {isDownloading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Downloading...
                </>
              ) : (
                'Download Video'
              )}
            </a>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default VideoPlayerModal;
