import { api } from '../common/axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;

export const getAllDealers = async (data: any) => {
  if (data != '') {
    const newHeader = {
      'Content-Type': 'application/json'
    };
    return await api.get(`${API_URL}dealers`, {
      headers: newHeader,
      params: {
        brand: data
      },
    });
  }
};

export const getDealersVideos = async (data: any) => {
  const { userid, pageNumber, selectedDealer } = data;
  if (selectedDealer != undefined && selectedDealer != '' && selectedDealer != '0') {
    const newHeader = {
      'Content-Type': 'application/json'
    };

    try {
      const response = await api.get(`${API_URL}videos`, {
        headers: newHeader,
        params: {
          pageNumber,
          userid,
          uuid: selectedDealer
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching dealer videos:', error);
      throw error;
    }
  }
};

export const getBrands = async () => {
  return api.get(`${API_URL}dealers/brands`).then((response: any) => {
    return response.data;
  });
};

export const getUserBrands = async (uuid: string) => {
  return api.get(`${API_URL}dealers/user-brands?userId=${uuid}`).then((response: any) => {
    return response.data;
  });
};

export const getUserDealers = async (data: any) => {
  return api.get(`${API_URL}dealers/user-dealers?userUuid=${data?.userId}&brandUuid=${data.brand}`).then((response: any) => {
    return response.data;
  });
};