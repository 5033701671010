import { Formik,Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Signup.css"; 
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signUpHandler, userHandler } from "../../Store/slices/authSlice";
import { IUser } from "../../../types/user.type";
import * as ACTION from "../../Store/actions/index";
import { toast } from "react-toastify";

const Signup = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues: IUser = {
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Username is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleRegister = async (formValue: IUser) => {
    await dispatch(signUpHandler(formValue)).then((res: any) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        dispatch(userHandler(false))
        dispatch(signUpHandler({}))
        navigate("/signin");
      }
      // if (res?.type == `${ACTION.SIGNUP}/${ACTION.FULFILLED}`) {
      //   toast.success(res?.payload?.message, {
      //       position: 'top-right',
      //       autoClose: 2000,
      //   });
      //   redirect("/signin")
      // }
      else if (res?.type == `${ACTION.SIGNUP}/${ACTION.REJECTED}`){
        toast.error(res?.payload?.response?.data?.message, {
            position: 'top-right',
            autoClose: 2000,
        });
      }
    });
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            <div className="headingsContainer">
              <h3>Sign up</h3>
              <p>Create your account</p>
            </div>

            <div className="mainContainer">
              <div className="input-container">
                <label htmlFor="username">Username</label>
                <Field
                  type="text"
                  id="userName"
                  name="userName"
                  placeholder="Enter Username"
                  required
                />
                <ErrorMessage
                  name="userName"
                  component="div"
                  className="text-danger txt-error"
                />
              </div>

              <div className="input-container">
                <label htmlFor="email">Email</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  required
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger txt-error"
                />
              </div>

              <div className="input-container">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  required
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger txt-error"
                />
              </div>

              <div className="input-container">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  required
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-danger txt-error"
                />
              </div>
              <button type="submit" className="signup-btn mb-3">Sign Up</button>
              <p className="login">
                Already have an account? <a href="/signin">Login here!</a>
              </p>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Signup;
