export const PENDING = "pending";
export const FULFILLED = "fulfilled";
export const REJECTED = "rejected";

/// dispatch auth actions ///
export const SIGNUP = "signUp";
export const LOGIN = "signIn";
export const FORGOTPASSWORD = "forgotPassword";
export const RESETPASSWORD = "resetPassword";

export const VIDEODATA = "videodata";
export const SCRATCH_DETECT_VIDEO = "ScratchDetectVideo";

export const UPLOAD_VIDEO ='uploadVideo';
export const GET_VIDEO_URL ='getVideoUrl';
export const GET_VIDEO_DETAILS = 'getVideoDetails';
export const GET_SCRATCH_VIDEO_DETAILS = 'getScratchVideoDetails';
export const SUBMIT_VIDEO_URLS = 'submitVideoUrls';
export const SUBMIT_SCRATCH_VIDEO_URLS = 'submitScratchVideoUrls';
export const VIDEO_UPLOAD_S3 = 'uploadVideoS3';
export const SCRATCH_VIDEO_UPLOAD_S3 = 'scratchUploadVideoS3';
export const ADD_VIDEO = 'addVideo';
export const ADD_SCRATCH_VIDEO = 'add-scratch-video';
export const FETCH_THUMBNAILS = 'getThumbnails';
export const RETRY_VIDEO_ANALYSIS= 'retryVideoAnalysis';
export const RETRY_SCRATCHED_VIDEO_ANALYSIS= 'retryScratchedVideoAnalysis';
export const GET_TRANSCRIPTION_TEXT = 'getTranscriptionText'
export const DELETE_VIDEO = 'deleteVideo'
export const DELETE_SCRATCH_VIDEO = 'deleteScratchVideo'
export const GET_ALL_DEALERS = 'getAllDealers';
export const GET_DEALERS_VIDEOS = 'getDealersVideos';
export const GET_CUSTOM_OBJECT_PERCENT = 'getCustomObjectPercent'
export const GET_BRANDS = 'getAllBrands';
export const GET_VIDEO_RATING_PERCENT= 'getVideoScorePercent';
export const GET_BRAND_VIDEO_RATING_PERCENT  = 'getBrandVideoRatingPercent'
export const GET_USER_BRANDS = 'getUserBrands'
export const GET_USER_DEALERS = 'getUserDealers'
export const GET_BRANDS_TOTAL_VIDEO_COUNT  =  'getBrandsTotalVideoCount'
export const GET_BRANDS_TOTAL_VIDEO_AVG_LENGTH = 'getBrandsTotalVideoAvgLength'
export const GET_BRANDS_TOTAL_VIDEO_NOISY_RATE = 'getBrandVideosNoisyRate'
export const GET_BRANDS_TOTAL_VIDEO_SUCCESS_RATE = 'getBrandsVideoSuccessRate'
export const GET_BRANDS_TOTAL_VIDEO_FAILURE_RATE = 'getBrandVideoFailureRate'
