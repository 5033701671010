import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer'
import videoReducer from './slices/videoSlice';
import authReducer from './slices/authSlice';
import dealersReducer from './slices/dealerSlice';
import userReducer from './slices/userSlice'
import loaderSlice  from './slices/commonSlice';
//Reducers 
const rootReducer = combineReducers({
  video: videoReducer,
  dealers: dealersReducer,
  auth: authReducer,
  users: userReducer,
  common: loaderSlice,
})

//persist configuration
const persistConfig: any = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

//store configuration
export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
})
export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

