// Modal.js
import { Modal } from "react-bootstrap";

const VideoUrlModalComponent = ({ isOpen, onClose, children, modalHeading, onSubmit }: any) => {
  const videoUrldata = children.map((e: any) => {
    return <span>{e}</span>
  })
  return (
    <>
      <Modal show={isOpen} size="xl" centered>
        <Modal.Header>
          <h5 className="modal-title">
            {modalHeading}
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClose}
          >
          </button>
        </Modal.Header>
        <div className="video_links">
          {videoUrldata}
        </div>
        <Modal.Footer>
          <div className='d-flex w-100 justify-content-end '>
            <button className='btn btn-primary w-auto ms-3 mt-0' onClick={onSubmit}>
              Submit
            </button>
            <button className='btn btn-danger w-auto ms-3 mt-0' onClick={onClose}>
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoUrlModalComponent;
