import { useEffect } from 'react';
import { api } from './axiosInstance';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../pages/Store/store';
import * as routeConstant from './routeConstants';
import { useDispatch, useSelector } from 'react-redux';
import { enableLoader } from '../pages/Store/slices/commonSlice';
import { logoutHandler } from '../pages/Store/slices/authSlice';

const AuthVerify = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, admin } = useSelector((state: RootState) => state.auth);
    let token = user?.token || admin?.token;
    //For GET requests
    api.interceptors.request.use(
        (req) => {
            dispatch(enableLoader(true))
            return req;
        },
        (err) => {
            if (err.response.status === 401) {
                dispatch(logoutHandler());
            } else {
                return Promise.reject(err);
            }
        }
    );

    // For POST requests
    api.interceptors.response.use(
        (res) => {
            dispatch(enableLoader(false));
            if (res.status === 201) {
            }
            return res;
        },
        (err) => {
            dispatch(enableLoader(false));
            if (err.response.status === 418) {
                dispatch(logoutHandler());
                navigate(routeConstant.SIGNIN);
            }
            if (err.response.status === 401) {
                dispatch(logoutHandler());
                navigate(routeConstant.SIGNIN);
            } else {
                return Promise.reject(err);
            }
        }
    );

    const isTokenExpired = (token: string) => {
        if (!token) return true;
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp && decodedToken.exp < currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    };
 
    useEffect(() => {
        if (token) {
            if (isTokenExpired(token)) {
                dispatch(logoutHandler());
                toast.error("Session Timeout. Please login again", {
                    position: "top-right",
                    autoClose: 2000
                })
                navigate(routeConstant.SIGNIN);
            }
        } else {
            navigate(routeConstant.SIGNIN);
        }
    }, [token]);
    return (<></>);
}
export default AuthVerify;