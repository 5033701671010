import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllBrandsHandler, getAllDealersHandler } from '../Store/slices/dealerSlice';
import Header from '../../components/Header';
import { toast } from 'react-toastify';
import { asignedBrandAndDealerHandler, getBrandDealerByUserUuidHandler } from '../Store/slices/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen, faSave } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';

const AsignedBrandAndDealers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { uuid } = useParams();

    const [selectedBrands, setSelectedBrands] = useState<any[]>([]);
    const [selectedAllBrandsDealers, setSelectedAllBrandsDealers] = useState<boolean>(false);
    const [selectedAllBrandsDealersValue, setSelectedAllBrandsDealersValue] = useState<any>([]);
    const [dealersByBrand, setDealersByBrand] = useState<{ [brandId: string]: any[] }>({});
    const [brandDealerMap, setBrandDealerMap] = useState<{ brand: string, dealers: { value: string }[] }[]>([]);
    const [latestCheckedBrand, setLatestCheckedBrand] = useState<string | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);

    useEffect(() => {
        dispatch(getAllBrandsHandler()).then((res: any) => {
            if (res?.payload?.statusCode === 200) {
                const brandForUser = res.payload.data?.map((e: any) => ({ value: e.uuid, label: e.name }));
                setSelectedBrands(brandForUser);
            }
        });
    }, [dispatch]);

    const fetchDealers = (brandId: string) => {
        if (!dealersByBrand[brandId]) {
            dispatch(getAllDealersHandler(brandId)).then((res: any) => {
                const dealers = res.payload.data.data.map((dealer: any) => ({
                    value: dealer.uuid,
                    label: dealer.name,
                }));
                setDealersByBrand((prev) => ({
                    ...prev,
                    [brandId]: dealers,
                }));
            });
        }
    };

    const handleBrandToggle = (value: string) => {
        const isSelected = !!brandDealerMap.find((brandDealer) => brandDealer.brand === value);

        if (isSelected) {
            setLatestCheckedBrand(null);
            setBrandDealerMap((prev) => prev.filter((brandDealer) => brandDealer.brand !== value));
        } else {
            fetchDealers(value);
            setLatestCheckedBrand(value);
            setBrandDealerMap((prev) => [
                ...prev,
                {
                    brand: value,
                    dealers: [],
                },
            ]);
        }
    };


    const handleDealerToggle = (brandId: string, dealervalue: string) => {
        const selective = !!brandDealerMap.find(brandDealer => brandDealer.brand === latestCheckedBrand)?.dealers.find(d => d.value === dealervalue)

        setBrandDealerMap((prev) => {
            return prev.map((brandDealer) => {
                if (brandDealer.brand === brandId) {
                    if (!selective) {
                        return {
                            ...brandDealer,
                            dealers: [...brandDealer.dealers, { value: dealervalue }],
                        };
                    } else {
                        return {
                            ...brandDealer,
                            dealers: brandDealer.dealers.filter((dealer) => dealer.value !== dealervalue),
                        };
                    }
                }
                return brandDealer;
            });
        });
    };

    const handleSelectAllChange = (brandId: string, checked: boolean) => {
        const dealers = dealersByBrand[brandId];
        if (dealers) {
            setBrandDealerMap((prev) => {
                return prev.map((brandDealer) => {
                    if (brandDealer.brand === brandId) {
                        if (checked) {
                            return {
                                ...brandDealer,
                                dealers: dealers.map(dealer => ({ value: dealer.value })),
                            };
                        } else {
                            return {
                                ...brandDealer,
                                dealers: [],
                            };
                        }
                    }
                    return brandDealer;
                });
            });
        }
    };

    const handleSelectAllBrandsAndDealers = (e: any) => {
        console.log(e.value, "eeee")
        setSelectedAllBrandsDealers(e.target.checked);
        if (e.target.checked) {
            setLatestCheckedBrand(null)
            setSelectedAllBrandsDealersValue({ SelectAll: e.target.value });
        } else {
            setSelectedAllBrandsDealersValue({ SelectAll: '' });
        }
    };

    const validateForm = () => {
        let isValid = true;
        let errorMessage = '';

        if (selectedAllBrandsDealers) {
            if (selectedAllBrandsDealersValue.SelectAll !== 'SelectAll') {
                isValid = false;
                errorMessage = 'Please select all brands and dealers correctly.';
            }
        } else {
            if (brandDealerMap.length === 0) {
                isValid = false;
                errorMessage = 'At least one brand and one dealer must be selected.';
            } else {
                const invalidBrands = brandDealerMap.filter(brandDealer => brandDealer.dealers.length === 0);
                if (invalidBrands.length > 0) {
                    isValid = false;
                    errorMessage = 'Please select at least one dealer for each selected brand.';
                }
            }
        }

        setValidationError(errorMessage);
        return isValid;
    };

    const handleAsignedBrandandDealers = async () => {
        if (!validateForm()) return;
        const userUUid: any = uuid;
        const brandAndDealersData: any = selectedAllBrandsDealers ? selectedAllBrandsDealersValue : brandDealerMap;
        const data = {
            uuid: userUUid,
            brandAndDealer: brandAndDealersData
        }
        await dispatch(asignedBrandAndDealerHandler(data)).then((response: any) => {
            if (response.payload === true) {
                setBrandDealerMap([]);
                toast.success("Brand And Dealers Added Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                });
                navigate('/admin/dashboard');
            }
        }).catch((error: any) => { console.log(error); });
    };

    const getAllBrandAnddealersByUser = async () => {
        const userUUid: any = uuid;
        await dispatch(getBrandDealerByUserUuidHandler(userUUid)).then((response: any) => {
            const transformedData = response.payload.data?.data[0].assignedBrands.map((brand: any) => ({
                brand: brand.uuid,
                dealers: brand.assignedDealers.map((dealer: any) => ({
                    value: dealer.uuid,
                })),
            }));

            setBrandDealerMap(transformedData);
            for (const brand of response.payload.data?.data[0].assignedBrands) {
                if (!dealersByBrand[brand.uuid]) {
                    fetchDealers(brand.uuid);
                }
            }
        }).catch((error: any) => { console.log(error); });
    };
    useEffect(() => {
        getAllBrandAnddealersByUser();
    }, [uuid]);

    return (
        <div className="container-fluid">
            <div className="mt-5 mb-4 pb-2">
                <Header />
            </div>

            <div className='d-flex justify-content-between my-4 px-5'>
                <div>
                    <button className='btn btn-warning rounded-4 px-3 mw-120 ' onClick={() => navigate('/admin/dashboard')}> <FontAwesomeIcon icon={faArrowLeft} />Back</button>
                </div>

                <div>
                    <button className='btn btn-success rounded-4 px-3 mw-120' onClick={handleAsignedBrandandDealers} >Save  <FontAwesomeIcon icon={faSave} /></button>
                </div>
            </div>

            {validationError && <div className="alert alert-danger">{validationError}</div>}

            <div className='row'>
                <div className='col-md-6 side-brands'>
                    <div className='px-lg-5'>
                        <div className='brand-label'>Brands</div>
                        <div className='text-center my-3'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => handleSelectAllBrandsAndDealers(e)}
                                        value={"SelectAll"}
                                    />}
                                label={'Select All Brands & Dealers'}
                                sx={{ '& .MuiFormControlLabel-label': { fontSize: 20 } }}
                            />
                        </div>
                    </div>

                    {!selectedAllBrandsDealers ?
                        <div className='ps-lg-5'>
                            <table className='select-brand-table table table-borderless table-sm table-middle'>
                                <tbody>
                                    {selectedBrands.map((data, index) => (
                                        <tr>
                                            <td key={index} className='brand-name1' >
                                                <div
                                                    className={`toggle-button ${brandDealerMap.find(brandDealer => brandDealer.brand === data.value) ? 'active' : ''}`}
                                                    onClick={() => handleBrandToggle(data.value)}
                                                >
                                                    {data.label}
                                                </div>
                                            </td>
                                            <td className='brand-name2' width={50}>
                                                {brandDealerMap.find(brandDealer => brandDealer.brand === data.value) &&
                                                    <IconButton
                                                        aria-label="edit-brand"
                                                        color="primary"
                                                        size="small"
                                                        className='size-42 bg-primary bg-opacity-10 rounded-circle text-primar d-flex align-items-center justify-content-center p-2'
                                                        onClick={() => {
                                                            fetchDealers(data.value);
                                                            setLatestCheckedBrand(data.value);
                                                        }}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </IconButton>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div style={{ fontSize: "40px", margin: "40px" }}>
                            SELECTED ALL BRANDS AND DEALERS</div>
                    }
                </div>
                <div className='col-md-6 sideDealers'>
                    <div className='px-lg-5'>
                        {latestCheckedBrand &&
                            <>
                                <div className='dealer-label'>  Dealers for {selectedBrands.find(b => b.value === latestCheckedBrand)?.label}</div>
                                <div className='text-center my-3'>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => handleSelectAllChange(latestCheckedBrand, e.target.checked)} checked={dealersByBrand[latestCheckedBrand]?.length > 0 && brandDealerMap.find(brandDealer => brandDealer.brand === latestCheckedBrand)?.dealers.length === dealersByBrand[latestCheckedBrand]?.length}
                                            />}
                                        label={'Select All'}
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: 20 } }}
                                    />
                                </div>
                                <div className='vstack gap-3'>
                                    {dealersByBrand[latestCheckedBrand]?.map((dealer, index) => (
                                        <div className='dealer-name1'>
                                            <div
                                                className={`toggle-button ${brandDealerMap.find(brandDealer => brandDealer.brand === latestCheckedBrand)?.dealers.find(d => d.value === dealer.value) ? 'active' : ''}`}
                                                onClick={(e: any) => handleDealerToggle(latestCheckedBrand, dealer.value,)}
                                            >
                                                {dealer.label}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AsignedBrandAndDealers;
