import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import PasswordField from "../../../components/PasswordField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { logoutHandler, resetPasswordHandler } from "../../Store/slices/authSlice";
import { Spinner } from "react-bootstrap";

const ResetPassword = () => {
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  //formik starts here
  const initialValues: any = {
    newPassword: "",
    confirmPassword: ""
  };


  useEffect(() => {
    dispatch(logoutHandler());
}, []);
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("This field is required").transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
      .test(
        "len",
        "Password must be between 6 to 40 characters",
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
        }
      ).test(
        "no-spaces",
        "Spaces are not allowed",
        (val: any) =>
          val &&
          val.trim() !== ''
      ).notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password'),


    confirmPassword: Yup.string()
      .required("This field is required").transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
      .test(
        "len",
        "Password must be between 6 to 40 characters",
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
        }
      ).test(
        "no-spaces",
        "Spaces are not allowed",
        (val: any) =>
          val &&
          val.trim() !== ''
      ).oneOf([Yup.ref('newPassword')], 'Passwords must match')
  });


  const handleRegister = async (formValue: any, { resetForm }: any) => {
     const password = formValue?.newPassword
        try {
            setLoading(true);

            const data = {
                token, password
            }
            await dispatch(resetPasswordHandler(data)).then((result: any) => {
                setLoading(false);
                if (result?.payload?.statusCode === 200) {
                    toast.success(result?.payload?.message, {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                    setTimeout(() => {
                        navigate('/signin')
                    }, 1000);
                }
                else if (result?.payload?.statusCode === 401) {
                    toast.error(result?.payload?.message, {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
                else if (result?.payload?.statusCode === 400) {
                    toast.error(result?.payload?.message, {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
            }).catch((error: any) => {
                console.log(error)
            })
        } catch (error) {
            console.error(error, "error");
        }

  };
    return (
        <div className="vh-100 bg-primary-light">
          <div className="container vh-100">
            <div className="row justify-content-center h-100">
              <div className="col-md-8 col-xl-6 hstack align-items-center justify-content-center">
                <div className="card border-0 mb-5 shadow w-100 p-4">
                  <div className="card-body">
                    <div className="fs-4 fw-semibold mb-5 text-center hstack gap-4">
                      <span>Change Password</span>
                      <FontAwesomeIcon icon={faUnlockKeyhole} className="text-primary" />
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleRegister}
                    >
                      <Form className="vstack gap-4">
                        <PasswordField name="newPassword" label="New Password" />
                        <PasswordField name="confirmPassword" label="Confirm Password" />
    
                        <div className="text-center mt-4">
                          <button className="btn btn-primary rounded-4 px-4 py-2" type="submit" disabled={loading}>
                          {loading && <Spinner animation={'border'} size='sm' />}{
                            "Set New Password"
                          }
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default ResetPassword
