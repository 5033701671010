import { api } from '../common/axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;

export const userData = async (pageLimit: number, perPage: number, searchVal: string, sortColumn: string, sortType: string) => {
  try {
    const response = await api.get(`${API_URL}users/all`, {
      params: {
        pageNumber: pageLimit,
        limit: perPage,
        searchVal: searchVal,
        sortColumn: sortColumn,
        sortType: sortType
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const asignedBrandAndDealer = async (uuid: string, brandAndDealer: any) => {
  return api.post(`${API_URL}users/add/brands-dealers/${uuid}`, brandAndDealer).then((response: any) => {
    return response.data;
  });
};

export const createUser = async (userData: any) => {
  try {
    const response = await api.post(`${API_URL}users/add`, userData)
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message
      };
    } else {
      return {
        success: false,
        message: response.data.message
      };
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getUserByUuid = async (uuid: any) => {
  try {
    const response = await api.get(`${API_URL}users?userId=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

export const getUserDetailsByUuid = async (uuid: any) => {
  try {
    const response = await api.get(`${API_URL}users/userdetail/${uuid}`);
    return response;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};
export const getBrandDealersUserByUuid = async (uuid: any) => {
  try {
    const response = await api.get(`${API_URL}users/brand-dealer-user/${uuid}`);
    return response;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

export const deleteUser = async (uuid: any) => {
  try {
    const response = await api.delete(`${API_URL}users?userId=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const updateUserByUuid = async (data: any) => {
  try {
    if (data.uuid != undefined && data.uuid != null && data.uuid != '') {
      const response = await api.put(`${API_URL}users?userId=${data?.uuid}`, {
        data
      });
      return response;
    }
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};
