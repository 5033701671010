import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { RootState } from "../pages/Store/store";
import { videoHandler, videoUploadToS3Handler, addVideoHandler } from "../pages/Store/slices/videoSlice";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ADD_VIDEO, UPLOAD_VIDEO_REJECT, UPLOAD_VIDEO_S3 } from '../common/commonConstants';
import { useTranslation } from 'react-i18next';
import { TailSpin } from "react-loader-spinner";

const VideoUpload = (dealer: any) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileName, setFileName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { showLoader } = useSelector((state: RootState) => state.common);
  const user_id = user?.user?.uuid ?? user?.uuid;
  const MAX_FILE_SIZE = 1024 * 1024 * 500; // 100MB
  const SUPPORTED_FORMATS = ["video/mp4", "video/avi", "video/mov","video/quicktime"];
  const [loader, setLoader] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const file = event?.target?.files[0];
      validateAndUpload(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event?.dataTransfer?.files) {
      const file = event?.dataTransfer?.files[0];
      validateAndUpload(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validateAndUpload = (file: File) => {
    if (file?.size > MAX_FILE_SIZE) {
      toast.error(t('commonToastMsg.fileSizeLimit'), {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    if (!SUPPORTED_FORMATS.includes(file.type)) {
      toast.error(t('commonToastMsg.fileFormat'), {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    setSelectedFile(file);
    setFileName(file.name);
    handleUpload(file);
  };

  const handleUpload = async (file: File) => {
    setLoading(true);

    const userId = user_id;
    const data = {
      video: file,
      userId
    }
    dispatch(videoUploadToS3Handler(data)).then(async (res: any) => {
      setLoader(true)
      if (res?.type === UPLOAD_VIDEO_S3) {
        const putUrl = res.payload.putUrl;
        const response = await fetch(putUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file
        });
        let videoUpload = false;
        if (200 === response.status) {
          videoUpload = true;
        }
        const apiData = {
          userId: userId,
          videoUuid: res.payload.videoUuid,
          fileName: res.payload.fileName,
          videoUpload,
          uuid: dealer.dealerId
        }
        dispatch(addVideoHandler(apiData)).then(async (res: any) => {
          setLoading(false);
          if (res?.type === ADD_VIDEO) {
            const selectedBrand = localStorage.getItem('selectedBrand');
            await dispatch(videoHandler({ userid: userId, pageNumber: 1, brand: selectedBrand, uuid: dealer.dealerId }));
            setSelectedFile(null);
            setFileName('');
            if (fileInputRef.current) {
              fileInputRef.current.value = ''; // Reset the input field
            }
            toast.success(t('VideoProcess.videoUploadSuccess'), {
              position: "top-right",
              autoClose: 2000,
            });
          } else {
            toast.error(res?.payload?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
        });
      }
      else if (res?.type === UPLOAD_VIDEO_REJECT) {
        setLoading(false);
        toast.error(res?.payload?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }).catch((error: any) => {
      setLoading(false);
      toast.error(t('VideoProcess.videoUploadFailed'), {
        position: "top-right",
        autoClose: 2000,
      });
    });
  };

  useEffect(()=>{
    if(showLoader){
      setLoader(false)
    }
  },[showLoader])

  return (
    <div className="text-center">
        {loader && <div className="loader-overlay">
        <div className="loader-container">
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      </div>}
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
      >
        <input
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <div>
          <div className="my-4">
            <FontAwesomeIcon icon={faUpload} size="2x" className='mb-3' />
            <p className='fs-4 fw-bold'>{t('Dashboard.dragDropPlaceholder')}</p>
            <div className='text-black'>{t('Dashboard.supportedPropsPlaceholder')}</div>
          </div>
          <div>
            <button className='btn btn-primary custom-btn'>{t('Dashboard.browseFile')}</button>
          </div>
          {fileName && <p className='mb-0 mt-2'>{t('Dashboard.selectFile')}: {fileName}</p>}
        </div>
      </div>
    </div>
  );
};

export default VideoUpload;
