import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useState } from "react";

interface PasswordFieldProps {
    name: string;
    label: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({ name, label }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => (
                <FormControl fullWidth variant="standard" error={meta.touched && Boolean(meta.error)}>
                    <InputLabel htmlFor={name}>{label}</InputLabel>
                    <Input
                        {...field}
                        id={name}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} size="xs" />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <ErrorMessage name={name} component="div" className="text-danger txt-error" />
                </FormControl>
            )}
        </Field>
    );
};

export default PasswordField