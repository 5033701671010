import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface BrandVideoScoreRatingChartProps {
    brandsVideoRatingPercent: any;
}

const BrandVideoScoreRatingChart: React.FC<BrandVideoScoreRatingChartProps> = ({ brandsVideoRatingPercent }:any) => {
  let categories = brandsVideoRatingPercent?.map((data:any) => data.brand);
  let seriesData = brandsVideoRatingPercent?.map((data:any) => data.rating);
  
    const options: ApexOptions = {
        series: seriesData,
          chart: {
            type: "radialBar",
            width: '100'
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                }
              },
              barLabels: {
                enabled: true,
                useSeriesColors: true,
                // margin: 8,
                fontSize: '16px',
                formatter: function(seriesName, opts) {
                  return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
              },
            }
          },
          labels: categories,
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          title: {
            text: "Brand video rating",
            align: "center",
            floating: true,
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                  show: false
              }
            }
          }]
    }

    return (
        <div className="radial-chart">
            <Chart options={options} series={options.series} type="radialBar" height={500} />
        </div>
    );
};

export default BrandVideoScoreRatingChart;
