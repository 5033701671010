import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface BarChartProps {
    brandsVideoObjectsPercent: any;
    chartRef: React.RefObject<HTMLDivElement>;
}

const BarChart: React.FC<BarChartProps> = ({ brandsVideoObjectsPercent,chartRef }) => {
    let categories: string[] = [];
    let seriesData: Array<{ name: string, data: number[] }> = [];

    if (brandsVideoObjectsPercent && brandsVideoObjectsPercent.length > 0) {
        categories = Object.keys(brandsVideoObjectsPercent[0].percentData);

        seriesData = brandsVideoObjectsPercent.map((data: any) => {
            return {
                name: data.dealerName,
                data: Object.values(data.percentData)
            };
        });
    }

    
    
    const options: ApexOptions = {
        series: seriesData,
        chart: {
            height: 450,
            type: 'bar',
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%'
            },
          },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: categories,
          },
          yaxis: {
            title: {
              text: '% (Percentage)'
            }
          },
        title: {
            text: 'Video Objects',
            floating: true,
            // offsetY: 330,
            align: 'center',
            style: {
                color: '#444'
            }
        }
    };

    return (
        <div className="radial-chart"  ref={chartRef}>
            <Chart options={options} series={options.series} type="bar" height={450} />
        </div>
    );
};

export default BarChart;
