import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { register, login, forgotPassword, resetPassword } from '../../../services/auth.service'
import { get } from 'lodash';
import * as actions from '../actions/index';

//auth initial states
export interface AuthState {
    signUpIsLoading: boolean,
    successful: boolean,
    message: string,
    signInIsLoading: boolean,
    signInSuccesfull: boolean,
    signInMessage: string,
    user: any,
    isUser: boolean
    tourComplete: boolean
    hideBackButton: boolean
    isAdmin: boolean,
    admin: any,
    isFirstLogin: boolean,
    userDealers: any,
    userBrands: any,
    selectedBrandId: any,
    selectedDealerId: any,
    forgotPasswordMessage: string,
    forgotPasswordIsLoading: boolean,
    forgotPasswordSuccesfull: boolean
}

const initialState: AuthState = {
    signUpIsLoading: false,
    message: '',
    successful: false,
    signInIsLoading: false,
    signInSuccesfull: false,
    signInMessage: '',
    user: {},
    isUser: false,
    tourComplete: false,
    hideBackButton: true,
    isAdmin: false,
    admin: {},
    isFirstLogin: true,
    userBrands: [],
    userDealers: [],
    selectedBrandId: '',
    selectedDealerId: '',
    forgotPasswordMessage: '',
    forgotPasswordIsLoading: false,
    forgotPasswordSuccesfull: false
}

export interface IUser {
    id?: any | null,
    userName: string,
    email: string,
    password: string
}

export interface IUserLogin {
    email: string;
    password: string;
}

//auth api thunks
export const signUpHandler: any = createAsyncThunk(actions.SIGNUP, (data: IUser, { rejectWithValue }) =>
    register(data?.userName.trim(), data?.email.trim(), data?.password.trim()).catch((error) => error && rejectWithValue(error))
);

export const signInHandler: any = createAsyncThunk(actions.LOGIN, (data: IUserLogin, { rejectWithValue }) =>
    login(data?.email.trim(), data?.password.trim()).catch((error) => error && rejectWithValue(error))
)
export const forgotPasswordHandler: any = createAsyncThunk(actions.FORGOTPASSWORD, (data: any, { rejectWithValue }) =>
    forgotPassword(data?.email.trim()).catch((error) => error && rejectWithValue(error))
);
export const resetPasswordHandler: any = createAsyncThunk(actions.RESETPASSWORD, (data: any, { rejectWithValue }) =>
    resetPassword(data?.token, data?.password).catch((error) => error && rejectWithValue(error))
);

//authSlice
export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        userHandler: (state, { payload }: PayloadAction<any>) => {
            state.user = payload;
            state.isUser = payload;
            if (payload.roleUser == true) {
                state.admin = {};
                state.isAdmin = false;
            }
        },
        userFirstLoginHandler: (state) => {
            state.isFirstLogin = false;
        },
        userBrandHandler: (state, { payload }: PayloadAction<any>) => {
            state.userBrands = payload;
        },
        userDealerHandler: (state, { payload }: PayloadAction<any>) => {
            state.userDealers = payload;
        },
        userSelectedBrandHandler: (state, { payload }: PayloadAction<any>) => {
            state.selectedBrandId = payload;
        },
        userSelectedDealerHandler: (state, { payload }: PayloadAction<any>) => {
            state.selectedDealerId = payload;
        },
        adminHandler: (state, { payload }: PayloadAction<any>) => {
            state.admin = payload;
            state.isAdmin = true;
        },
        logoutHandler: (state) => {
            state.user = {};
            state.isUser = false;
            state.admin = {};
            state.isAdmin = false;
            sessionStorage.clear();
            localStorage.clear();
            // const currentAppVersion = localStorage.getItem('app_version') ?? '0.0.0';
            // localStorage.setItem("app_version", currentAppVersion);
            // state.selectedBrandId = '';
            // state.selectedDealerId = '';
        },
        messageHandler: (state) => {
            state.message = '';
        },
        signInMessageHandler: (state) => {
            state.signInMessage = '';
        },
        hideBackButtonHandler: (state, { payload }: PayloadAction<any>) => {
            state.hideBackButton = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signUpHandler.pending, (state, action) => {
            state.signUpIsLoading = true;
        })
        builder.addCase(signUpHandler.fulfilled, (state, action) => {
            state.isUser = true;
            state.successful = true;
            state.tourComplete = true;
            state.user = action.payload;
            state.signUpIsLoading = false;
        })
        builder.addCase(signUpHandler.rejected, (state, action) => {
            const error = action;
            const networkError = get(error, 'payload.message');
            const errorMessage = get(error, 'payload.response.data.message');
            if (errorMessage) {
                state.message = errorMessage;
            } else if (networkError) {
                state.message = networkError;
            }
            state.signUpIsLoading = false;
        })
        builder.addCase(signInHandler.pending, (state, action) => {
            state.signInMessage = '';
            state.signInIsLoading = true;
        })
        builder.addCase(signInHandler.fulfilled, (state, action) => {
            // state.user = action.payload;
            // state.isUser = true;
            // state.signInIsLoading = false;
            // state.signInSuccesfull = true;
            // state.signInMessage = ("Login successfully");
        })
        builder.addCase(signInHandler.rejected, (state, action) => {
            const error = action;
            const networkError = get(error, 'payload.message');
            const errorMessage = get(error, 'payload.response.data.message');
            if (errorMessage) {
                state.signInMessage = errorMessage;
            } else if (networkError) {
                state.signInMessage = networkError;
            }
            state.signInIsLoading = false;
            state.signInSuccesfull = false;
        })
        builder.addCase(forgotPasswordHandler.pending, (state, action) => {
            state.forgotPasswordMessage = '';
            state.forgotPasswordIsLoading = true;
        })
        builder.addCase(forgotPasswordHandler.fulfilled, (state, action) => {
            state.forgotPasswordIsLoading = false;
            state.forgotPasswordSuccesfull = true;
        })
        builder.addCase(forgotPasswordHandler.rejected, (state, action) => {
            const error = action;
            const networkError = get(error, 'payload.message');
            const errorMessage = get(error, 'payload.response.data.message');
            if (errorMessage) {
                state.forgotPasswordMessage = errorMessage;
            } else if (networkError) {
                state.forgotPasswordMessage = networkError;
            }
            state.forgotPasswordIsLoading = false;
            state.forgotPasswordSuccesfull = false;
        })
    }
})

export const { messageHandler, signInMessageHandler, logoutHandler, userHandler, hideBackButtonHandler, adminHandler, userFirstLoginHandler, userBrandHandler, userDealerHandler, userSelectedBrandHandler, userSelectedDealerHandler } = authSlice.actions;
export default authSlice.reducer;