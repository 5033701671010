export const SIGNIN = '/signin'
export const SIGNUP = '/signup'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password/:token'
export const DASHBOARD = '/dashboard'
export const ADMIN_DASHBOARD = '/admin/dashboard'
export const VIDEOS = '/videos'
export const SCRATCHDETECT = '/scratch-videos-detect'
export const CHANGE_PASSWORD = '/changepassword/:uuid'
export const ADMIN_SIGNIN = '/auth/signin'
export const SELECT_BRAND_DEALERS = '/manage/brands-dealers/:uuid'
export const USER_CREATE = '/admin/create-user'
export const USER_EDIT = '/admin/edit-user'
export const SHOW_USER_DETAILS = '/show-user-details/:uuid'

