import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import { get } from "lodash";
import { getThumbnails } from '../../../services/thumbnails.service';
import { getAllDealers, getBrands, getDealersVideos, getUserBrands, getUserDealers } from "../../../services/dealers.service";

export interface DealersState {
    dealers: any[];
    selectedDealer: any;
    brands: any;
    isBrandSelected:boolean;
    isDealerSelected:boolean
}

const initialState: DealersState = {
    dealers: [],
    selectedDealer: {},
    brands: [],
    isBrandSelected:false,
    isDealerSelected:false
};

export const getAllDealersHandler: any = createAsyncThunk(
    actions.GET_ALL_DEALERS,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getAllDealers(data);
            return response;
        } catch (error) {
            return (error);
        }
    }
);

export const getDealersVideoHandler: any = createAsyncThunk(
    actions.GET_DEALERS_VIDEOS,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getDealersVideos(data);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllBrandsHandler: any = createAsyncThunk(
    actions.GET_BRANDS,
    async () => {
        try {
            const response = await getBrands();
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return error;
        }
    }
);

export const getAllUserBrandsHandler: any = createAsyncThunk(
    actions.GET_USER_BRANDS,
    async (userId:string) => {
        try {
            const response = await getUserBrands(userId);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return error;
        }
    }
);

export const getUserDealersHandler: any = createAsyncThunk(
    actions.GET_USER_DEALERS,
    async (data:any) => {
        try {
            const response = await getUserDealers(data);
            return response; // Assuming the response contains the thumbnail data
        } catch (error) {
            return error;
        }
    }
);
export const dealersSlice = createSlice({
    name: 'dealersSlice',
    initialState,
    reducers: {
        dealersHandler: (state, { payload }: PayloadAction<any>) => {
            state.dealers = payload;
        },
        isBrandSelectedHandler : (state, { payload }: PayloadAction<any>) => {
            state.isBrandSelected = payload;
        },
        isDealerSelectedHandler : (state, { payload }: PayloadAction<any>) => {
            state.isDealerSelected = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDealersHandler.pending, (state, action) => {
            })
            .addCase(getAllDealersHandler.fulfilled, (state, action) => {
                state.dealers = action.payload
            })
            .addCase(getAllDealersHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            })
            .addCase(getAllBrandsHandler.pending, (state, action) => {
            })
            .addCase(getAllBrandsHandler.fulfilled, (state, action) => {
                state.brands = action.payload.data
            })
            .addCase(getAllBrandsHandler.rejected, (state, action) => {
                console.error('request failed:', action.error.message);
            });
    },
});

export const { dealersHandler ,isBrandSelectedHandler ,isDealerSelectedHandler} = dealersSlice.actions;
export default dealersSlice.reducer;

