import * as XLSX from 'xlsx';
// Import toaster notification (assuming a system like react-toastify or antd's notification)
import { toast } from 'react-toastify'; // Replace with the toaster you are using

// Utility function to convert camelCase or snake_case to Title Case
const formatHeader = (header: string): string => {
    return header.replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
                 .replace(/_/g, ' ') // Replace underscores with spaces
                 .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()); // Capitalize the first letter of each word
};

export const exportToExcelFile = async (data: any[], reportOption: string) => {
    try {
        // Check if data is empty
        if (!data || data.length === 0) {
            toast.error("No data found to export.", {
                position: "top-right",
                autoClose: 2000
            });
            return null;
        }

        const wb = XLSX.utils.book_new(); // Create a new workbook

        // Check if dealers exist in the first item of the data
        if (Array.isArray(data[0]?.dealers)) {
            data.forEach((item: any) => {
                // Skip if dealers array is empty
                if (!item?.dealers || item?.dealers.length === 0) return null;
                
                // Ensure the first dealer exists before attempting to get its keys
                if (!item?.dealers[0]) {
                    console.error("No dealer data available for:", item);
                    return;
                }
                
                // Get headers dynamically from the first dealer
                const headers = Object.keys(item.dealers[0]); // This won't throw an error now
                const formattedHeaders = headers.reduce((acc: { [key: string]: string }, key: string) => {
                    acc[key] = formatHeader(key);
                    return acc;
                }, {});

                // Map data with formatted headers
                const wsData = item.dealers.map((dealer: any) => {
                    const formattedDealer: { [key: string]: any } = {};
                    headers.forEach((header: string) => {
                        formattedDealer[formattedHeaders[header]] = dealer[header];
                    });
                    return formattedDealer;
                });

                // Create a worksheet and append it to the workbook
                const ws = XLSX.utils.json_to_sheet(wsData);
                XLSX.utils.book_append_sheet(wb, ws, item.brand); // Append sheet for each brand
            });
        } else {
            // Handle the case when data doesn't contain dealers
            const headings = Object.keys(data[0]); // Extract headings from the first item

            // Format the data to match the dynamic headings
            const formattedData = data
            .filter(item => item) // Filter out any null or undefined items
            .map((item: any) => {
                const formattedItem: { [key: string]: any } = {};
                headings.forEach((heading: string) => {
                    formattedItem[formatHeader(heading)] = item[heading];
                });
                return formattedItem;
            });

            // Create a worksheet and append it to the workbook
            const ws = XLSX.utils.json_to_sheet(formattedData);
            XLSX.utils.book_append_sheet(wb, ws, "Report");
        }

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `${reportOption}.xlsx`);

        // Show success toaster
        toast.success("Excel file downloaded successfully!", {
            position: "top-right",
            autoClose: 2000
        });
    } catch (error) {
        // In case of any error, show a toaster with error message
        console.error("Error exporting Excel file:", error);
        toast.error("Failed to export Excel file. Please try again.", {
            position: "top-right",
            autoClose: 2000
        });
    }
};
