import React, { FC } from 'react';

//props interface starts here
interface DeleteProps {
    show: any;
    question: string;
    btncancel: string;
    btnyes: string;
    clickOk: any;
    clickCancel: any;
    description?: any
}
//props interface ends here

const DialogBox: FC<DeleteProps> = ({ show, question, btncancel, btnyes, clickOk, clickCancel, description }) => {

    return show &&
        <div className='delete-confirm'>
            <div className='delete-confirm-inner'>
                <p className='delete-confirm-title mb-4'>{question}</p>
                {description &&
                    <div className="description mb-4">{description}</div>
                }

                <div className='hstack justify-content-center gap-3'>
                    <button className='btn btn-primary btn-lg rounded-4 mw-120' type='button' onClick={() => { clickCancel() }}>{btncancel}</button>
                    <button className='btn btn-danger btn-lg rounded-4 mw-120' type='button' onClick={() => { clickOk() }}>{btnyes}</button>
                </div>
            </div>
        </div>
}

export default DialogBox;