import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@mui/material'
import { ErrorMessage, Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from "yup"
import { defaultLanguage, languages, phoneRegExp, specialCharacterAndAlphabetRegex, userStatus } from '../../common/common'
import PasswordField from '../../components/PasswordField'
import { ICreateUser, Pair } from '../../types/user.type'
import { getAllBrandsHandler, getAllDealersHandler, isBrandSelectedHandler, isDealerSelectedHandler } from '../Store/slices/dealerSlice'
import { createUserHandler, editUserByIdHandler, getUserByIdHandler, updateUserByUuidhandler } from '../Store/slices/userSlice'
import { RootState } from '../Store/store'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
 
interface BrandDealerPair {
    brand: Pair;
    dealers: Pair[];
}
 
interface SelectFieldProps {
    name: string;
    label: string;
    options: Pair[];
}
 
const SelectField: React.FC<SelectFieldProps> = ({ name, label, options }) => {
    const { setFieldValue } = useFormikContext();
 
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOption = options.find(option => option.value === event.target.value);
        if (selectedOption) {
            setFieldValue(name, selectedOption.value);
        }
    };
    return (
        <Field name={name}>
            {({ field }: FieldProps) => (
                <div>
                    <TextField
                        select
                        label={label}
                        fullWidth
                        variant="standard"
                        value={field.value}
                        onChange={handleChange}
                    >
                        {options.map((option) => (
                            <MenuItem key={`s1${option.value}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ErrorMessage name={name} component="div" className="text-danger txt-error" />
                </div>
            )}
        </Field>
    );
};
 
const CreateAndEditUser = () => {
 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const [trigger, setTrigger] = useState(false);
 
    const { editUserId } = useSelector((state: RootState) => state.users);
    const [brandDealerPairs, setBrandDealerPairs] = useState<BrandDealerPair[]>([]);
    const [createUserLoading, setCreateUserLoading] = useState(false);
 
    useEffect(() => {
        dispatch(getAllBrandsHandler()).then((res: any) => {
            if (res?.payload?.statusCode === 200) {
                const brandForUser = res.payload.data?.map((e: any) =>
                    ({ value: e.uuid, label: e.name })
                ).filter((item: Pair) => item.label === "TruVideo")
                if (editUserId === '') {
                    const defaultBrand = brandForUser.filter((item: Pair) => item.label === "TruVideo")[0]
                    dispatch(getAllDealersHandler(defaultBrand.value)).then((res: any) => {
                        const dealers = res.payload.data.data.map((dealer: any) => ({
                            value: dealer.uuid,
                            label: dealer.name
                        }));
                        const pair = [{ brand: defaultBrand, dealers: dealers }]
                        setBrandDealerPairs(pair)
                    });
                }
            }
        });
    }, [editUserId]);
 
    const isUpdate = !!editUserId && editUserId !== '' ? true : false
 
    const initialValues: ICreateUser = {
        firstName: "",
        lastName: "",
        email: "",
        isUpdate: isUpdate,
        password: "",
        status: userStatus[0].value,  //ACTIVE
        contact: "",
        lang: defaultLanguage.value,
    };
 
    const [editUserData, setEditUserData] = useState<ICreateUser>(initialValues);
 
    // Define a regex pattern for password validation
    const passwordRegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("This field is required").test(
            "len",
            t('validation.firstnameLimit'),
            (val: any) => {
                const trimmedValue = val && val.toString().trim();
                return trimmedValue && trimmedValue.length >= 3 && trimmedValue.length <= 50;
            }
        ),
        lastName: Yup.string()
            .nullable()
            .max(50)
            .test(
                'valid-last-name',
                t('validation.invalidLastName'), // Your combined error message
                (val) => {
                    const trimmedValue = val && val.toString().trim();
                    return !trimmedValue || (/[a-zA-Z]/.test(trimmedValue) && specialCharacterAndAlphabetRegex.test(trimmedValue));
                }
            ),
        email: Yup.string().required("This field is required").email("Please enter a valid email"),
        isUpdate: Yup.boolean(),
        password: Yup.string()
            .when("isUpdate", {
                is: false,
                then: (schema) => schema
                    .required("This field is required")
                    .min(6, "Password should be more than 6 characters").test('trimmed', 'Password cannot have leading and trailing spaces', (val) => {
                        const trimmedValue = val && val.toString().trim();
                        return trimmedValue === val;
                    })
                    .matches(passwordRegExp, {
                        message: "Password must contain at least one uppercase letter, one number, and one special character",
                        excludeEmptyString: true
                    }),
 
                otherwise: (schema) => schema.notRequired().nullable()
            }),
        contact: Yup.string().matches(phoneRegExp, 'Contact number is not valid'),
    });
 
    const clearForm = () => {
        setEditUserData(initialValues)
        setBrandDealerPairs([]);
        dispatch(editUserByIdHandler(''))
    };
 
    const handleUserCreate = async (formValue: ICreateUser) => {
 
        if (createUserLoading) return;
        const { firstName, lastName, email, password, contact, status, lang } = formValue;
        const userName = `${firstName.trim()} ${lastName?.trim()}`;
 
        if (isUpdate) {
            const data = { uuid: editUserId, userName, firstName, lastName, email, status, lang };
            if (lastName && lastName?.trim().length > 0) {
                data.lastName = lastName?.trim()
            } else {
                data.lastName = null
            }
            await dispatch(updateUserByUuidhandler(data)).then((response: any) => {
                if (response.type === "updateUserByUuid/fulfilled") {
                    if (response.payload.data.statusCode === 200) {
                        toast.success(response.payload.data.message, { position: 'top-right', autoClose: 2500 });
                        clearForm();
                        navigate('/admin/dashboard');
                    }
                }
            })
        } else {
            let data = { userName, firstName, lastName, email, password, contact, status, lang, brandDealerPairs: brandDealerPairs };
            if (lastName && lastName?.trim().length > 0) {
                data.lastName = lastName?.trim()
            } else {
                data.lastName = null
            }
            dispatch(createUserHandler(data)).then((res: any) => {
                if (res?.type === 'createUser/fulfilled') {
                    toast.success(res.payload.message, { position: 'top-right', autoClose: 2500 });
                    clearForm();
                    setCreateUserLoading(false);
                    navigate('/admin/dashboard');
                } else {
                    toast.error(res.payload.response.data.message, { position: 'top-right', autoClose: 2500 });
                }
            });
        }
 
    };
 
    const getUserByUuids = async () => {
        await dispatch(getUserByIdHandler(editUserId)).then(async (response: any) => {
            if (response.type === 'getUserById/fulfilled') {
                const { fName, lName, email, lang, contact, status, assignedBrands } = response.payload.data.data;
                var brandDealerObj: any = {}
                const userBrandDealerPair =
                    assignedBrands.map((brand: any) => {
                        brandDealerObj = {
                            brand: { value: brand.uuid, label: brand.name },
                            dealers: []
                        };
 
                        brand.assignedDealers.map(async (dealer: any, index: number) => {
                            const dealerObj = {
                                value: dealer.uuid,
                                label: dealer.name
                            };
                            brandDealerObj['dealers'].push(dealerObj);
                        })
 
                        return brandDealerObj;
                    })
 
                setBrandDealerPairs(userBrandDealerPair)
 
                const initialValues: ICreateUser = {
                    firstName: fName,
                    lastName: lName,
                    email: email,
                    isUpdate: isUpdate,
                    password: '',
                    status: status,
                    contact: contact,
                    lang: lang
                };
                setEditUserData(initialValues);
            }
        })
 
    }
 
    useEffect(() => {
        if (editUserId !== '') {
            getUserByUuids()
        }
    }, [editUserId]);
 
    const setTriggerfn = (data: any) => {
        setTrigger(true);
        setTimeout(() => {
            setTrigger(false);
        }, 500);
    };
 
    return (
        <div className="container-fluid">
            <div className="mt-5 mb-4 pb-2">
                <Header setTrigger={setTriggerfn} />
            </div>
            <div className="container-fluid d-flex justify-content-center">
 
                <div className="card border-0 shadow w-50">
                    <div className="card-body">
                        <div className="font-22 fw-medium">User Details</div>
                        <Formik
                            enableReinitialize={true}
                            initialValues={editUserData}
                            validationSchema={validationSchema}
                            onSubmit={handleUserCreate}
                        >
                            <Form id='userForm' className='vstack gap-4'>
                                <div>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="firstName"
                                        label="First Name"
                                        variant="standard"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="text-danger txt-error" />
                                </div>
 
                                <div>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="lastName"
                                        label="Last Name"
                                        variant="standard"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="text-danger txt-error" />
                                </div>
 
                                <div>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        variant="standard"
                                        disabled={isUpdate}
                                    />
                                    <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                                </div>
 
                                {
                                    isUpdate &&
                                    <div>
                                        <SelectField
                                            name="status"
                                            label="Status"
                                            options={userStatus}
                                        />
                                        <ErrorMessage name="status" component="div" className="text-danger txt-error" />
                                    </div>
                                }
 
                                {
                                    !isUpdate &&
                                    <PasswordField name="password" label='Password' />
                                }
 
                                <div>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        name="contact"
                                        label="Contact"
                                        variant="standard"
                                        disabled={isUpdate}
                                    />
                                    <ErrorMessage name="contact" component="div" className="text-danger txt-error" />
                                </div>
 
                                <div>
                                    <SelectField
                                        name="lang"
                                        label="Select Language"
                                        options={languages}
                                    />
                                    <ErrorMessage name="lang" component="div" className="text-danger txt-error" />
                                </div>
 
                                {
                                    !isUpdate &&
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        placeholder="brand"
                                        label="Brand"
                                        variant="standard"
                                        value="TrueVideo"
                                        disabled={true}
                                        readOnly={true}
                                    />
                                }
 
                                {
                                    !isUpdate &&
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        placeholder="brand"
                                        label="Dealer"
                                        variant="standard"
                                        value="Dealer 1"
                                        disabled={true}
                                        readOnly={true}
                                    />
                                }
                                <div className='text-center'>
                                    <button type='submit' className="btn btn-primary rounded-4 w-50" disabled={createUserLoading}>{!isUpdate ? "Create User" : "Save"}</button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
interface SelectBrandDealerProps {
    brandOptions: any
    index: number
    brandDealerPairsCallback: (data: any) => void
    brandDealerPairs: any[]
}
 
const SelectBrandDealer: React.FC<SelectBrandDealerProps> = ({ brandOptions, index, brandDealerPairsCallback, brandDealerPairs }) => {
    const dispatch = useDispatch()
    const [dealerOptions, setDealerOptions] = useState<any[]>([]);
    const [selectedValues, setSelectedValues] = useState<any[]>([]);
 
    const selectedBrandValues = brandDealerPairs.map(pair => pair.brand.value);
    const filteredBrandOptions = brandOptions.filter((option: any) => !selectedBrandValues.includes(option.value) || option.value === brandDealerPairs[index].brand.value);
 
    const handleBrandChange = async (e: any) => {
        const value: any = e.target.value
 
        await dispatch(getAllDealersHandler(value)).then((res: any) => {
 
            const dealers = res.payload.data.data.map((dealer: any) => ({
                value: dealer.uuid,
                label: dealer.name
            }));
 
            setDealerOptions(dealers)
            setSelectedValues([])
            dispatch(isBrandSelectedHandler(value !== null))
            dispatch(isDealerSelectedHandler(false))
 
            const data = { index: index, brand: { value: value, label: getLabelFromValue(value) } }
            brandDealerPairsCallback(data)
 
        });
    };
 
    const getLabelFromValue = (value: string) => {
        const label = brandOptions.find((option: any) => option.value === value)?.label;
        return label
    };
 
    useEffect(() => {
        if (brandDealerPairs[index].brand.value === null) {
            setDealerOptions([])
            setSelectedValues([])
            dispatch(isBrandSelectedHandler(false))
        } else {
            setSelectedValues(brandDealerPairs[index].dealers.map((item: any) => item.value));
            dispatch(getAllDealersHandler(brandDealerPairs[index].brand.value)).then((res: any) => {
 
                const dealers = res.payload.data.data.map((dealer: any) => ({
                    value: dealer.uuid,
                    label: dealer.name
                }));
 
                setDealerOptions(dealers)
                dispatch(isBrandSelectedHandler(true))
            });
        }
    }, [brandDealerPairs])
 
    return (
        <div className='vstack gap-4 bg-primary-subtle rounded-1 py-1 px-2 align-items-center'>
            <TextField
                select
                InputLabelProps={{
                    shrink: selectedBrandValues[0] === null ? false : true,
                }}
                label="Select Brand"
                fullWidth
                variant="standard"
                value={brandDealerPairs[index].brand.value}
                onChange={handleBrandChange}
            >
                {filteredBrandOptions?.map((option: Pair) => (
                    <MenuItem key={`s3${option.value}`} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
 
            <DealerField
                index={index}
                dealerOptions={dealerOptions}
                brandDealerPairsCallback={brandDealerPairsCallback}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
            />
        </div>
    );
};
 
interface DealerFieldProps {
    dealerOptions: Pair[];
    index: number;
    brandDealerPairsCallback: (data: { index: number, dealers: Pair[] }) => void;
    selectedValues: string[];
    setSelectedValues: (value: string[]) => void;
}
 
const DealerField: React.FC<DealerFieldProps> = ({ dealerOptions, index, brandDealerPairsCallback, selectedValues, setSelectedValues }) => {
    const { isBrandSelected, isDealerSelected } = useSelector((state: RootState) => state.dealers);
    const [open, setOpen] = useState(false);
 
    const dispatch = useDispatch()
    const handleChange = (event: any) => {
        const value: any = event.target.value;
        if (value.includes('')) {
            return; // Do nothing if an empty string is detected
        }
        if (value.length === dealerOptions.length) {
            dispatch(isDealerSelectedHandler(true))
        } else {
            dispatch(isDealerSelectedHandler(false))
        }
 
        setSelectedValues(value);
        const data = { index: index, dealers: value.map((item: string) => ({ value: item, label: getLabelFromValue(item) })) };
        brandDealerPairsCallback(data);
 
        // If there's only one dealer option, close the dropdown
        if (dealerOptions.length === 1) {
            setOpen(false);
        }
    };
 
    const handleSelectAll = () => {
        if (isDealerSelected) {
            setSelectedValues([]);
            const data = { index: index, dealers: [] };
            brandDealerPairsCallback(data);
            dispatch(isDealerSelectedHandler(false))
        } else {
            setSelectedValues(dealerOptions.map((option: Pair) => option.value));
            const data = { index: index, dealers: dealerOptions };
            brandDealerPairsCallback(data);
            dispatch(isDealerSelectedHandler(true))
        }
    };
 
    const getLabelFromValue = (value: string) => {
        const label = dealerOptions.find(option => option.value === value)?.label;
        return label || value;
    };
 
    useEffect(() => {
        if (dealerOptions.length === 1) {
            setOpen(false);
        }
    }, [dealerOptions]);
 
    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id={`dealer-label-${index}`} shrink={selectedValues.length > 0 ? true : false}>Select Dealers</InputLabel>
            <Select
                labelId={`dealer-label-${index}`}
                multiple
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                name='dealers'
                value={selectedValues}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, idx) => (
                            <Chip key={`selectedValues${value}${idx}`} label={getLabelFromValue(value)} />
                        ))}
                    </Box>
                )}
                onChange={handleChange}
            >
                {isBrandSelected && dealerOptions.length > 1 &&
                    <MenuItem key={`dealerOptionsSelectAll`} value={''}>
                        <ListItemIcon>
                            <Checkbox
                                checked={isDealerSelected}
                                indeterminate={selectedValues.length > 0 && selectedValues.length < dealerOptions.length}
                                onChange={handleSelectAll}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </MenuItem>
                }
                {dealerOptions?.map((option: Pair) => (
                    <MenuItem key={`dealerOptions${option.value}`} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
 
export default CreateAndEditUser