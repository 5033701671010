// Modal.js
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from "react-bootstrap";

const ModalComponent = ({ isOpen, onClose, children, modalHeading }: any) => {
  return (
    <>
      <Modal className='video-details-modal' show={isOpen} size="xl" centered >
        <Modal.Header>
          <h5 className="modal-title fw-semibold">
            {modalHeading}
          </h5>
          <button
            type="button"
            aria-label="Close"
            className='btn focus-none'
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} className='fs-3 text-white'/>
          </button>
        </Modal.Header>
        <div className="">
          {children}
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
