import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { register, login } from '../../../services/auth.service'
import { get } from 'lodash'
import * as actions from '../actions/index';
import { commonData } from '../../../common/interface';

//loader initial states
const initialState: commonData = {
    showLoader: false,
   };
 

//loaderSlice
export const loaderSlice = createSlice({
    name: 'loaderSlice',
    initialState,
    reducers: {
        enableLoader:(state, { payload }: PayloadAction<boolean>) => {
            state.showLoader = payload;
        },
    },
 
    extraReducers: (builder) => {
    
    }
})

export const {enableLoader} = loaderSlice.actions;
export default loaderSlice.reducer;

export const hideLoader = () => (dispatch:any) => {
    dispatch(enableLoader(false));
  };